@charset "utf-8";

body {
	line-height: 18px;
	font-size: 13px;
	font-family: Helvetica,Arial,sans-serif;
	margin: 0px;
	// padding: 0px 5px;
	color: #333;
	@media screen and (max-width: 991px){
		font-size: 16px;
		line-height: 24px;
	}
}

h1,h2,h3,h4,h5,h6,p {
	padding: 0;
	margin: 0;
}

#header ul,.sidebar ul,#footer .nav_top ul, .box_partner ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

h1 {
	font-size: 19px;
}

h2 {
	font-size: 17px;
}

h3 {
	font-size: 15px;
}

h4,h5,h6 {
	font-size: 14px;
}

img {
	border: 0px;
	outline: none;
}

a {
	color: #333;
	text-decoration: none;
	outline: none;
}

a:hover {
	text-decoration: underline;
}

blockquote,q {
	quotes: none;
}

blockquote:before,blockquote:after,q:before,q:after {
	content: none;
}

/* remember to define focus styles! */
:focus {
	outline: 0;
}
input,textarea,select{
	border-radius: 3px;
	border: 1px solid #D3D3D3;
}
input:focus,textarea:focus {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 4px #E5E5E5;
	overflow: hidden;
}
/* remember to highlight inserts somehow! */
ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

.highlight{
  background-color: yellow;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.clear {
	clear: both;
}

.clearL {
	clear: left;
}

.clearR {
	clear: right;
}
.hidden{display: none;}
#flashMessage {
	margin: 0 auto;
	width: 100%;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 10000;
}
#flashMessage span{
	border-radius: 3px;
	padding-left: 32px;
	display: block;
	width: 470px;
	line-height: 35px;
	margin: 0 auto;
	background: #D31010;
	color: #fff;
	box-shadow: 0 0 3px #aaa;
}
#flashMessage.success span {
	background-image: url('img/success.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

#flashMessage.error span {
	background-image: url('img/error.png');
	background-position:  8px center;
	background-repeat: no-repeat;
}

.error-message {
	color: red;
	line-height: 22px;
	float: left;
	text-align: left;
	padding-left: 5px;
}
.form .error-message{
	display: block;
	float: none;
	margin-bottom: 5px;
	padding: 0;
	font-size: 11px;
}

.float_left {
	float: left;
}

.float_right {
	float: right;
}

a.act,
span.act {
	padding: 1px 6px;
	background-image: url(img/icon.png)
}

a.act:hover {
	text-decoration: none;
}

span.doc,span.docx{
	background-position: -37px -144px;
}
span.xls,span.xlsx{
	background-position: -66px -144px;
}
span.ppt,span.pptx{
	background-position: -123px -144px;
}
span.pdf{
	background-position: -95px -144px;
}
span.txt{
	background-position: -153px -144px;
}
span.zip,span.rar{
	background-position: -7px -144px;
}
span.link{
	background-position: -182px -144px;
}
a.download{
	background-position: -213px -144px;
	padding: 1px 7px;
}
a.download:hover{
	background-position: -244px -143px;
}


a.del {
	background-position: -8px -97px;
}

a.del:hover {
	background-position: -38px -97px;
}

/*** #tooltip ***/
#tooltip{
	position:absolute;
	border-radius: 2px;
	padding:3px 12px;
	color:#000;
	display:none;
	font-size: 13px;
	z-index: 1000;
	background: #ffffff;
	opacity: 0.9;
	box-shadow: 0px 0px 2px #333;
}

.detail_product #content .product_des,
#content .read .des{
	font-size: 14px;
	line-height: 24px;
	text-align: justify;
}
.detail_product #content .product_des p,
#content .read .des p{
	margin-bottom: 10px;
}

/********************************************************/
/********************** #wrapper *************************/
/********************************************************/
#wrapper {
	// margin: 0 auto;
/*	min-width: 1000px;*/
/*	max-width: 1500px;*/
	// width: 960px;
}

@media (min-width: 1200px) {
	.container {
		max-width: 960px;
	}
}


/********************************************************/
/********************** #header *************************/
/********************************************************/
/***** #header *****/
#header {
	position: relative;
	// height: 210px;
	margin-bottom: 10px;
	z-index: 1000;
}

#header input.small,#header input.medium,#header input.larger {
	padding: 2px;
}

#header input.small {
	width: 80px;
}

#header input.medium {
	width: 160px;
}

#header input.larger {
	width: 250px;
}

#header select {
	padding: 1px 2px;
}

#header select.small {
	width: 60px;
}
#header select.medium {
	width: 160px;
}

#header select.larger {
	width: 250px;
}

#header input.submit {
	background-image: url(img/button.png);
	background-position: -101px -1px;
	border: none;
	text-indent: -1000px;
	width: 34px;
	height: 22px;
}

#header input.submit:hover {
	cursor: pointer;
	background-position: -101px -23px;
}


/*** .logo ***/
#header .logo{
	position: absolute;
	top: 20px;
	left: 30px;
}

/***** .top *****/
#header .top {
	position: absolute;
	top: 3px;
	z-index: 100;
	width: 100%;
}

#header .top .social {
	float: left;
	margin-left: 10px;
}

#header .top ul {
	float: right;
	margin-right: 10px;
}

#header .top ul li {
	display: inline;
	padding-left: 6px;
}
#header .top ul li a{
	font-size: 12px;
	color: #444;
}

/***** .middle *****/
#header .middle {
	// position: absolute;
	// top: 0px;
	width: 100%;
}

/***** .bottom *****/
#header .bottom {
	// position: absolute;
	// bottom: 0;
	width: 100%;
}

/***** .search *****/
#header .search {
	float: right;
	margin-right: 10px;
	height: 22px;
}
#header .search input.medium{
	padding: 3px 5px 1px;
}

#header .search ul li {
	display: inline;
}

#header input.medium {
	width: 160px;
}


/***** .language *****/
#header ul.language {
	position: absolute;
	top: 14px;
	right: 15px;
	z-index: 1000;
}

#header ul.language li {
	display: inline;
}
ul.language li a{
	background-image: url(img/icon.png);
	background-repeat: no-repeat;
	height: 20px;
    width: 30px;
    float: left;
	margin-right: 6px;
	box-shadow: 0 0 2px #222;
}
ul.language li a:hover{
	text-decoration: none;
	box-shadow: 0 0 4px #222;
}
ul.language li a.vi{
	background-position: 0 -119px;
}
ul.language li a.en{
	background-position: -35px -119px;
}
ul.language li a.kr{
	background-position: -70px -119px;
}
ul.language li a.jp{
	background-position: -107px -119px;
}
ul.language li a.ch{
	background-position: -144px -119px;
}


/***** .cart ****/
#header .cart{
	float: right;
	margin-right: 10px;
}
#header .cart a{
	line-height: 20px;
}

/***** .currency *****/
#header .currency {
	float: right;
	margin-right: 5px;
	height: 22px;
}

/***** #navmenu *****/
#bg_nav,#bg_nav .left,#bg_nav .right{
	// height: 35px;
}

#bg_nav {
	background: rgb( 188, 64, 58);
	position: relative;
	z-index: 1000;
	float: left;
	margin-bottom: 3px;
	width: 100%;
	@media screen and (min-width: 991px) {
		height: 36px;
	}
}

#bg_nav .right,#bg_nav .left {
	width: 4px;
	position: absolute;
	top: 0;
	z-index: 2;
}

#bg_nav .right {
	position: absolute;
	right: 0;
	background: #fff url(img/h_nav_r.png) right top no-repeat;
}

#bg_nav .left {
	background: #fff url(img/h_nav_l.png) left top no-repeat;
	left: 0;
}

#header .nav{
	width: 100%;
}
.navbar-toggler {
	padding: 0;
	border: 0;
}
.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

#header .nav ul{
	z-index:100;
	// margin: 0 0 0 7px;
	padding: 0;
	list-style-type: none;
}

/*Top level list items*/
#header .nav ul li{
	position: relative;
	display: inline;
	float: left;
/* 	padding-left:5px; */
	margin-right: 1px;
	@media screen and (min-width: 991px){
		background: url(img/h_nav_li.png) right center no-repeat;
	}
}
#header .nav ul li.last{
	background: none;
}

/*Top level menu link items style*/
#header .nav ul li a{
	display: block;
	color: white;
	color: #2d2b2b;
	text-decoration: none;
/* 	padding-right:5px; */
}
#header .nav ul li span{
	height:35px;
	line-height:35px;
	padding: 0 1rem;
	display:block;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
}

* html #header .nav ul li a,
* html #header .nav ul li a span{ /*IE6 hack to get sub menu links to behave correctly*/
	display: inline-block;
}

#header .nav ul li a:link, #header .nav ul li a:visited{
	color: white;
}

/* hover cap 1 */
#header .nav ul li.selected,
#header .nav ul li:hover,
#header .nav ul li.iehover,
#header .nav ul li.current{ /*CSS class that's dynamically added to the currently active menu items' LI A element*/
/* 	background: url(img/h_nav_h_l.png) left top no-repeat;  */
}
#header .nav ul li.selected a,
#header .nav ul li a:hover,
#header .nav ul li.current a{
/* 	background: url(img/h_nav_h_r.png) right top no-repeat; */
	cursor:pointer;
	color: white;
}

#header .nav ul li.selected a span,
#header .nav ul li a span:hover,
#header .nav ul li a:hover span,
#header .nav ul li.current a span{
	background:rgb(80, 80, 80);
	// background: url(img/h_nav_h_c.png) left top repeat-x;
	color: #fff;
}
/* end hover cap 1 */


/*1st sub level menu*/
#header .nav ul li ul{
	@media screen and (min-width: 991px){
		position: absolute;
		left: 0;
	}
	display: none; /*collapse all sub menus to begin with*/
	visibility: hidden;
	margin: 0;
	width: max-content;
	// width: 200px;
}

/*Sub level menu list items (undo style from Top level List Items)*/
#header .nav ul li ul li{
	display: list-item;
	float: none;
	padding:0;
	margin: 0;
}

/*All subsequent sub menu levels vertical offset after 1st level sub menu */
#header .nav ul li ul li ul{
	top: 0;
	left: 100%;
}

/* Sub level menu links style */
#header .nav ul li ul li a,
#header .nav ul li.selected ul li a{
/*	width: 160px; width of sub menus*/
	margin: 0;
	padding:0 7px;
	height:28px;
	line-height:28px;
	border-bottom:1px solid #888;
	background:#777;
}

/* hover cap 2 tro di */
#header .nav ul li ul li a.selected,
#header .nav ul li ul li a:hover{
	background:#666;
	color:#fff;
}

/* Holly Hack for IE \*/
* html #header .nav{height: 1%;} /*Holly Hack for IE7 and below*/


/* ######### CSS classes applied to down and right arrow images  ######### */

.downarrowclass{
	position: absolute;
	top: 12px;
	right: 7px;
}

.rightarrowclass{
	position: absolute;
	top: 8px;
	right: 5px;
}

/* ######### CSS for shadow added to sub menus  ######### */

.ddshadow{ /*shadow for NON CSS3 capable browsers*/
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	background: silver;
}

.toplevelshadow{ /*shadow opacity for NON CSS3 capable browsers. Doesn't work in IE*/
	opacity: 0.8;
}



/********************************************************/
/*********************** #main **************************/
/********************************************************/
/***** #column_left *****/
#column_left {
	// float: left;
	// margin-right: -240px;
	// width: 100%;
}

/***** #sidebar_left *****/
#sidebar_left {
	// float: left;
	// width: 200px;
	// padding-top: 5px;
}

/***** #sidebar_right *****/
#sidebar_right {
	// float: right;
	// width: 240px;
}

/***** #content *****/
#content {
	// margin: 0 260px;
	// position: relative;
}

#content .top,#content .bottom {
	position: absolute;
	height: 5px;
	width: 100%;
	background: transparent;
}

#content .top {
	top: -5px;
}

#content .bottom {
	bottom: -5px;
}

#main.no_col_left #content {
	margin-left: 0;
}

#main.no_col_right #content{
	margin-right: 0;
}

#content .thumb a{
	line-height: 1px;
	display: block;
}

#content input {
	padding: 3px 5px;
}

#content select {
	padding: 2px 2px;
}

#content input.small {
	width: 90px;
}

#content input.medium {
	width: 200px;
}

#content input.larger {
	width: 300px;
}
#content input.larger1 {
	width: 246px;
}

#content select.small {
	width: 96px;
}
#content select.small1 {
	width: 70px;
}

#content select.medium {
	width: 206px;
}

#content select.larger {
	width: 313px;
}

#content textarea.small {
	width: 200px;
	height: 90px;
}

#content textarea.medium {
	width: 300px;
	height: 90px;
	padding: 5px;
}

#content textarea.larger {
	width: 400px;
	height: 90px;
}

#content span.im {
	color: #ff0000;
	margin-left: 5px;
}

#content label.error {
	color: #ff0000;
	display: block;
	font-size: 11px;
	font-style: italic;
}

/*********************** #sidebar **************************/
.sidebar {

}

.sidebar .sidebar_middle {

}

.sidebar .sidebar_bottom {

}

.sidebar .thumb a{
	display: block;
	line-height: 1px;
}

.sidebar ul {
	// margin: 0 auto;
	// width: 222px;
}

.sidebar input {
	padding: 2px 4px;
}

.sidebar select {
	padding: 2px 1px;
}

/***** .box *****/
.sidebar .box {
	// background: url(img/s_box_middle.png) top center repeat-y;
	position: relative;
	margin-bottom: 14px;
	padding-bottom: 10px;
}

.sidebar .box .top,.sidebar .box .bottom {
	position: absolute;
	height: 10px;
	width: 100%;
	z-index: 10;
	background: transparent;
}
.bg-gray {
	background-color: rgb(80, 80, 80);
}
.sidebar .box .top {
	// background: url(img/s_box_top.png) top center no-repeat;
	// top: -3px;
}

.sidebar .box .bottom {
	// background: url(img/s_box_bottom.png) bottom center no-repeat;
	// bottom: -3px;
}

.sidebar .box span.title {
	// background: url(img/s_box_title.png) top center no-repeat;
	// display: block;
	// text-align: center;
	// line-height: 20px;
	// height: 26px;
	// color: #fff;
	// margin-bottom: 10px;
	// font-weight: bold;
}

/*** .sidebar .category ***/
.sidebar .nav-v ul{
	margin: 0 auto;
	padding: 0;
	width: 180px; /* Main Menu Item widths */
	list-style-type: none;
	position: relative;
	z-index: 100;
}

.sidebar .nav-v ul li{
	position: relative;
}

/* Top level menu links style */
.sidebar .nav-v ul li a{
	display: block;
	overflow: auto; /*force hasLayout in IE7 */
	color: #333;
	text-decoration: none;
	padding: 6px;
	border-bottom: 1px solid #eee;
}

/*.sidebar .nav-v ul li a:link, .sidebar .nav-v ul li a:visited, .sidebar .nav-v ul li a:active{*/
/*	background: #fff; background of menu items (default state)*/
/*	color: 333;*/
/*}*/

.sidebar .nav-v ul li.current a,
.sidebar .nav-v ul li.selected a,
.sidebar .nav-v ul li a.selected,
.sidebar .nav-v ul li.selected ul a.selected{ /*CSS class that's dynamically added to the currently active menu items' LI A element*/
	color: #ff0000;
}

.sidebar .nav-v ul li a:hover{
	color: #ff0000 !important;
	text-decoration: underline;
}

/*Sub level menu items */
.sidebar .nav-v ul li ul{
	position: absolute;
	width: 180px; /*Sub Menu Items width */
	top: 0;
	font-weight: normal;
	visibility: hidden;
	background: #777777;
	border-radius: 5px;
}
.sidebar .nav-v ul li ul a{
	border-bottom: 1px solid #888;
}
.sidebar .nav-v ul li ul a:hover{
	color: #fff !important;
	font-weight: bold;
}
.sidebar .nav-v ul li.selected li a{
	color: #f9f9f9;
}
.sidebar .nav-v ul ul li:last-of-type a{
	border-bottom: none;
}

/* Holly Hack for IE \*/
* html .sidebar .nav-v ul li { float: left; height: 1%; }
* html .sidebar .nav-v ul li a { height: 1%;}
/* End */



/***** .maker .price *****/
.sidebar .maker ul li,.sidebar .box.price ul li {
	position: relative;
	line-height: 18px;
	padding: 4px 0;
}

.sidebar .maker ul li a {
	display: block;
}

.sidebar .box.maker ul a {
	background: url(img/arrow1.png) left 8px no-repeat;
	padding-left: 6px;
}
.sidebar .box.maker ul a:hover{
	color: #ff0000;
}

.sidebar .box.price ul a {
	background: url(img/arrow1.png) left 5px no-repeat;
	padding-left: 6px;
}
.sidebar .maker ul li.current a{
	font-weight: bold;
}
.sidebar .maker ul li img {
	position: absolute;
	top: 0;
	right: 0;
}

/***** .product_run *****/
.sidebar .product_run .caroufredsel_wrapper {
	width: 180px;
	margin: 0 auto !important;
	text-align: center;
	min-height: 150px !important;
}

.sidebar .product_run .thumb {
	position: relative;
	text-align: center;
	width: 160px;
	margin: 0 auto;
}

.sidebar .product_run .thumb span {
	display: block;
	width: 30px;
	height: 30px;
	background-image: url(img/icon.png);
	line-height: 30px;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-align: center;
	text-decoration: blink;
}

.sidebar .product_run .thumb span.discount {
	position: absolute;
	top: 0;
	left: 0;
	background-position: 0 0;
}

.sidebar .product_run .thumb span.promotion {
	position: absolute;
	top: 0;
	right: 0;
	background-position: -30px 0;
}

.sidebar .product_run p {
	text-align: center;
}

.sidebar .product_run p.price {
	color: #FF0000;
}

.sidebar .product_run p.price .old {
	text-decoration: line-through;
	color: #888;
}

.sidebar .product_run p.cart a {
	display: block;
	width: 89px;
	padding-right: 15px;
	line-height: 25px;
	background-image: url(img/button.png);
	background-position: 0px -66px;
	color: #fff;
	margin: 0 auto;
}

.sidebar .product_run p.cart a:hover {
	cursor: pointer;
	text-decoration: none;
	background-position: 0px -91px;
}

.sidebar .product_run ul li {
	padding-bottom: 10px;
	position: relative;
}

.sidebar .product_run p.cart {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	display: none;
}

.sidebar .product_run ul li:hover p.cart {
	display: block;
}

/***** .newsletter *****/
.sidebar .newsletter p {
	text-align: center;
}

.sidebar .newsletter button.submit {
	width: 90px;
	margin-top: 10px;
	background-image: url(img/button.png);
	background-position: -101px -132px;
	border: none;
	width: 97px;
	height: 24px;
	color: #fff;
}
.sidebar .newsletter #newsletter_result{
	display: none;
	color: #ff0000;
	font-style: italic;
	margin-top: 3px;
}

.sidebar .newsletter button.submit:hover {
	cursor: pointer;
	background-position: -101px -156px;
}

/***** .adv ****/
.sidebar .adv ul li{
	text-align: center;
}
.sidebar .adv .top,
.sidebar .adv .bottom,
.sidebar .adv .title{
	display: none !important;
	padding: 0;
}
.sidebar .adv{
	background: none;
	border: 1px solid #ddd;
	padding: 0;
}
.sidebar .adv ul{
	// margin: 0;
	// width: 238px;
}
.sidebar .adv li{
	line-height: 1px;
}


/***** .search *****/
.sidebar .search input {
	// margin-bottom: 10px;
}

.sidebar .search select {
	margin-bottom: 10px;
}
.sidebar .search .submit{
	text-align: center;
}
.sidebar .search .submit input{
	margin-bottom: 0;
	background-image: url(img/button.png);
	background-position: 0 -1px;
	border: none;
	padding-right: 15px;
	width: 97px;
	height: 24px;
	color: #fff;
}

.sidebar .search .submit input:hover {
	background-position: 0 -25px;
	cursor: pointer;
}

.sidebar .search p {
	text-align: center;
}

.sidebar .search li {
	text-align: center;
}

.sidebar #search_post {
	display: none;
}
.sidebar .search .submit{
	display: none;
}
.sidebar .search .bottom,
.sidebar .search .top,
.sidebar .search .title{
	display: none !important;
}
.sidebar .search{
	background: rgb(80, 80, 80);
	padding: 15px 0 15px;
}
.sidebar .search ul {
	// margin: 0 0 0 15px;
	// width: 180px;
}
.sidebar .search ul input{
	border: none;
}


/***** .support *****/
.sidebar .support li{
	padding-bottom: 8px;
	text-align: center;
}
.sidebar .support p{
	text-align: center;
	line-height: 22px;
	padding-bottom: 2px;
}
.sidebar .support p.name span{
	font-style: italic;
}

/***** .video *****/
.sidebar .video li{
	text-align: center;
}
.sidebar .video p.more{
	text-align: right;
	padding-right: 15px;
}


/***** .gallery *****/
.sidebar .gallery ul,
.sidebar .gallery .caroufredsel_wrapper{
	margin: 0 auto !important;
}
.sidebar .gallery li{
	text-align: center;
}


/***** .poll *****/
.sidebar .poll li{
	font-style: italic;
	font-weight: bold;
	line-height: 15px;
}
.sidebar .poll li ul li{
	font-weight: normal;
	font-style: normal;
}
.sidebar .poll li ul{
	margin-bottom: 10px;
}
.sidebar .poll .submit{
	text-align: center;
}
.sidebar .poll .submit input{
	background: url("img/button.png") -101px -132px no-repeat;
	height: 24px;
	width: 97px;
	border: none;
	color: #fff;
}
.sidebar .poll .submit input:hover{
	background-position: -101px -156px;
	cursor: pointer;
}
.sidebar .poll a.poll_result{
	background: url("img/button.png") -101px -132px no-repeat;
	padding: 5px 12px 4px;
	color: #fff;
}
.sidebar .poll a.poll_result:hover{
	background-position: -101px -156px;
	text-decoration: none;
}

#poll_result{
	position: fixed;
	top: 100px;
	left: 0;
	width: 100%;
	z-index: 10000;
	display: none;
}
#poll_result div{
	width: 500px;
	margin: 0 auto;
	background: #fff;
	border: 1px solid #aaa;
	border-radius: 5px;
	padding: 20px 10px;
	box-shadow: 0 0 7px #999;
}
#poll_result a.close{
	padding: 5px 12px;
	float: right;
	margin-right: -7px;
	margin-top: -17px;
	background: url(img/icon.png) -92px 0 no-repeat;
}
#poll_result a.close:hover{
	background-position: -122px 0;
	text-decoration: none;
}
#poll_result ul{
	width: 100%;
}
#poll_result ul li{
	margin-bottom: 20px;
}
#poll_result ul li ul{
	margin-top: 10px;
}
#poll_result ul li ul li{
	margin: 0;
}
#poll_result ul li div{
	padding: 0;
	box-shadow: none;
	border-radius: 3px;
	line-height: 18px;
	margin-bottom: 5px;
	border: 1px solid #0087E8;
}
#poll_result ul li div p{
	background: #0094FF;
	text-align: center;
	font-size: 12px;
	color: #fff;
}


/***** .post *****/
.sidebar .post .caroufredsel_wrapper{
	margin: 0 auto !important;
}
.sidebar .post ul li a {
	background: url(img/arrow2.png) left 6px no-repeat;
	padding-left: 8px;
}

/***** .document *****/
.sidebar .document ul li a {
	margin-left: 5px;
}

/***** .tag *****/
.sidebar .tag p {
	padding: 0 10px;
	text-align: center;
}

.sidebar .tag a {
	padding-right: 3px;
	line-height: 24px;
}

.sidebar .tag a.size1 {
	font-size: 15px;
}

.sidebar .tag a.size2 {
	font-size: 17px;
}

.sidebar .tag a.size3 {
	font-size: 19px;
}

.sidebar .tag a.size4 {
	font-size: 21px;
}

/***** .counter *****/
.sidebar .counter table {
	width: 160px;
	margin: 0 auto;
	font-family: Tahoma;
}

.sidebar .counter table th {
	text-align: left;
	font-weight: normal;
	padding-left: 20px;
}

.sidebar .counter table td {
	text-align: right;
}

.sidebar .counter tr.online th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.yesterday th {
	background: url(img/s_counter2.png) left 2px no-repeat;
}

.sidebar .counter tr.today th {
	background: url(img/s_counter3.png) left 2px no-repeat;
}

.sidebar .counter tr.week th {
	background: url(img/s_counter4.png) left 2px no-repeat;
}

.sidebar .counter tr.month th {
	background: url(img/s_counter5.png) left 2px no-repeat;
}

.sidebar .counter tr.year th {
	background: url(img/s_counter1.png) left 2px no-repeat;
}

.sidebar .counter tr.total th {
	background: url(img/s_counter_total.png) left 2px no-repeat;
}
.sidebar .counter tr.total_top th{
	text-align: center;
	line-height: 30px;
	padding: 0 0 5px 0;
}
.sidebar .counter #counter{
	text-align: center;
}
.sidebar .counter tr.total_top p {
	font-weight: bold;
	text-align: center;
	background: #333;
	color: #fff;
	font-size: 11px;
	margin: 0 auto 5px;
	font-size: 15px;
	border-radius: 3px;
	display: inline;
	padding: 2px 4px;
}
.sidebar .counter tr.total_top p span{
	padding: 0 4px;
	border-right: 1px solid #666;
}
.sidebar .counter tr.total_top p span:last-of-type{
	border: none;
}

/*********************** #content ***********************/
/***** .box_info_page *****/
#content .box_info_page {
	position: relative;
}

#content .box_info_page .top,#content .box_info_page .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#content .box_info_page .top {
	top: -5px;
}

#content .box_info_page .bottom {
	bottom: -5px;
}

#content .box_info_page .des {
	text-align: justify;
}

#content .box_info_page .title {
	line-height: 30px;
}

#content .box_info_page {
	margin-bottom: 15px;
}

#content a.add{
	background-position: -62px -97px;
}
#content a.add:hover{
	background-position: -82px -97px;
}
#content a.sub{
	background-position: -100px -97px;
}
#content a.sub:hover{
	background-position: -120px -97px;
}

/**** .tag *****/
#content div.tag{
	padding: 7px 0 8px;
}
#content div.tag span{
	background: url(img/tag.png) left center no-repeat;
	font-weight: bold;
	display: block;
	float: left;
	width: 82px;
	height: 22px;
	line-height: 26px;
	text-align: right;
	margin-right: 5px;
	text-transform: uppercase;
	color: #003BFF;
}
#content div.tag a{
	color: #003BFF
}

/**** .rate ****/
#content div.rate{
	margin-top: 8px;
}
#content div.rate span{
	float: left;
	line-height: 25px;
	padding-right: 10px;
	font-weight: bold;
}

/***** .box_content *****/
#content .box_content {
	position: relative;
	margin-bottom: 15px;
	// margin-right: -1px;		/** Sdung dac biet -- Tranh loi giao dien **/
}

#content .box_content .top,#content .box_content .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#content .box_content .top {
	top: -5px;
}

#content .box_content .bottom {
	bottom: -5px;
}

#content .box_content .des {
	// overflow: hidden;
	// padding: 3px 0 0 1px;
}

#content .box_content .title {
/* 	background: url(img/c_box_content_title_l.png) left top no-repeat; */
/* 	padding-left: 4px; */
}

#content .box_content .title_right {
/* 	background: url(img/c_box_content_title_r.png) right top no-repeat; */
/* 	padding-right: 4px; */
}

#content .box_content .title_center {
/* 	background: url(img/c_box_content_title_c.png) center top repeat-x; */
/* 	padding: 0 5px; */
	// height: 31px;
	// line-height: 31px;
	// position: relative;
}
#content .box_content.read .title,
#content .box_content.read .title_right,
#content .box_content.read .title_center{
	// background: none;
	// padding: 0;
	// height: auto;
}
#content .box_content.read .title span{
	line-height: 24px;
	font-size: 19px;
}

#content .box_content .title a.more {
	background-image: url(img/arrow3.png);
	background-position: 99% 13px;
	background-repeat: no-repeat;
	padding-right: 15px;
	font-size: 11px;
}

#content .box_content a.more:hover {
	background-position: 100% 10px;
}
#content .box_content .title span {
	font-size: 16px;
}

#content .box_content .title a {
	float: right;
}
#content .box_content .title nav{
	position: absolute;
	top: 0;
	right: 5px;
}
#content .box_content .title ul.sort {
	position: absolute;
	top: 0px;
	right: 5px;
	margin: 0;
	padding: 0;
	list-style: none;
}

#content .box_content .title ul.sort li {
	display: inline;
	float: left;
}

#content .box_content .title ul.sort li a {
	padding: 0 5px;
}

#content .box_content .title ul.sort li a.desc {
	background: url(img/c_sort_desc.png) 98% center no-repeat;
	padding-right: 10px;
}

#content .box_content .title ul.sort li a.asc {
	background: url(img/c_sort_asc.png) 98% center no-repeat;
	padding-right: 10px;
}

#content .box_content p.date-time{
	font-size: 12px;
	color: #555;
	font-style: italic;
}


/**** .product_run *****/
#content .product_run .caroufredsel_wrapper{
	margin-top: 5px !important;
	overflow: hidden;
}
#content .product_run ul{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;
}
#content .product_run ul li{
	float: left;
	text-align: left;
	border: 1px solid #ddd;
	width: 248px;
	height: 90px;
	margin: 2px;
	padding: 5px 3px;
}
#content .product_run ul li .thumb{
	float: left;
	margin: 0 3px 2px 0;
	position: relative;
}
#content .product_run ul li .thumb span{
	position: absolute;
	background: red;
	display: block;
	width: 30px;
	height: 30px;
	background: url("img/icon.png");
}
#content .product_run ul li .thumb img:hover{
	opacity: 0.5;
}
#content .product_run ul li .thumb span.discount{
	top: 0;
	left: 0;
	background-position: 0 0;
	color: #fff;
	text-align: center;
	line-height: 30px;
}
#content .product_run ul li .thumb span.promotion{
	top: 30px;
	left: 0;
	background-position: -30px 0;
}
#content .product_run ul li p.name{
	font-weight: bold;
	font-size: 12px;
}
#content .product_run ul li p.price{
	color: #ff0000;
}
#content .product_run ul li p.price span.old{
	display: block;
	text-decoration: line-through;
	color: #777;
}


/**** .sitemap ****/
#content .box_content.sitemap ul{
	margin: 10px 0 0 20px;
	padding: 0;
	list-style: none;
}
#content .box_content.sitemap ul li a{
	color: #333;
	font-weight: bold;
}
#content .box_content.sitemap ul ul a{
	font-weight: normal;
}
#content .box_content.sitemap ul li{
	background: url(img/arrow3.png) left 6px no-repeat;
	padding-left: 12px;
}
#content .box_content.sitemap ul ul{
	padding-bottom: 10px;
}
#content .box_content.sitemap ul ul li{
	background: url(img/arrow4.png) left 6px no-repeat;
}


/**** .search *****/
#content .search{
	// background: #eee;
	border-radius: 3px;
	// padding: 16px 10px 18px;
	// border: 1px solid #ddd;
	margin-bottom: 15px;
}
#content .search ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
#content .search ul li{
	margin: 5px 10px 5px 0;
	float: left;
}
#content .search .submit input{
	background: url("img/button.png") 0 -1px no-repeat;
	border: none;
	width: 97px;
	height: 24px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px;
	padding-right: 6px;
	margin-top: 5px;
}
#content .search .submit input:hover{
	background-position: 0 -25px;
	cursor: pointer;
}


/**** .banner ****/
#content .banner{
	text-align: center;
	margin-bottom: 10px;
	overflow: hidden;
}
#content .banner img{
	border-radius: 5px;
}


/**** .box_product ****/
#content .box_content .box_product {
	width: 185px;
	border: 1px solid #ddd;
	float: left;
	margin: -1px 0 0 -1px;
	padding: 8px 0;
	position: relative;
}

#content .box_content .box_product .top,#content .box_content .box_product .bottom
	{
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#content .box_content .box_product .top {
	top: -5px;
}

#content .box_content .box_product .bottom {
	bottom: -5px;
}

#content .box_content .box_product .thumb {
	position: relative;
	text-align: center;
	width: 170px;
	margin: 0 auto;
	padding: 2px;
	overflow: hidden;
	height: 118px;
}
#content .box_content .box_product .thumb a{
	display: block;
	line-height: 1px;
}

#content .box_content .box_product .thumb span {
	display: block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	background-image: url(img/icon.png);
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-decoration: blink;
}

#content .box_content .box_product .thumb span.discount {
	top: 0;
	right: 0;
	background-position: 0 0;
}

#content .box_content .box_product .thumb span.promotion {
	top: 30px;
	right: 1px;
	background-position: -30px 0;
}
#content .box_content .box_product a{
	color: #333;
}
#content .box_content .box_product p {
	text-align: center;
	padding: 0 5px;
}
#content .box_content .box_product p.name,
#content .box_content .box_product p.price{
	height: 20px;
	overflow: hidden;
}
#content .box_content .box_product p.price {
	color: #ff0000;
}
#content .box_content .box_product .des_scroll .name{
	font-size:14px;
	font-weight:bold;
}
#content .box_content .box_product .des_scroll p{
	margin:0px;
	padding:0px;
}
#content .box_content .box_product .des_scroll .price .lab,
#content .box_content .box_product .des_scroll .warranty span,
#content .box_content .box_product .des_scroll .promotion span{
	font-weight:bold;
	color:#333333;
}
#content .box_content .box_product p.price span.old {
	color: #999;
	text-decoration: line-through;
}
#content .box_content .box_product p.cart{
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
	padding: 0;
	display: none;
}
#content .box_content .box_product:hover p.cart{
	display: block;
}
#content .box_content .box_product p.cart a{
	display: block;
	width: 89px;
	padding-right: 15px;
	line-height: 25px;
	margin: 0 auto;
	text-align: center;
	background-image: url(img/button.png);
	background-position: 0 -66px;
	color: #fff;
}

#content .box_content .box_product p.cart a:hover {
	background-position: 0 -91px;
	text-decoration: none;
}

/**** .option_related ***/
#content .box_content .box_product.option_related{
	border: none;
}

/***** .des_scroll ******/
#content .box_product .des_scroll{
	position: absolute;
/*	height: 198px;*/
/*	width: 259px;*/
	text-align: justify;
	display: none;
	overflow: hidden;
	top: 0;
	left: 0;
}
#content .box_product .des_scroll .tb,
#content .box_product .des_scroll .bt,
#content .box_product .des_scroll .lr,
#content .box_product .des_scroll .rl{
	position: absolute;
	background: #f9f9f9;
	padding: 5px;
}

#content .box_product .des_scroll .lr,
#content .box_product .des_scroll .rl{
	top: 0;
}

#content .box_product .des_scroll .tb,
#content .box_product .des_scroll .bt{
	left: 0;
}

#content .box_product:hover .des_scroll{
	display: block;
}
#content .box_product .des_scroll p{
	text-align: justify;
}

/***************box_member_register****************/
#content .box_member_register{
	width:100%;
	margin-top:10px;
}
#content .box_member_register .unit{
	width:345px;
	float:left;
	border:1px solid #cecece;
	border-radius:5px;
	padding:10px;
	height:150px;
	position:relative;
}
#content .box_member_register .unit.left{
	margin-right:5px;
}
#content .box_member_register .unit h4{
	padding-bottom:10px;
	border-bottom:1px solid #cecece;
}
#content .box_member_register .unit p{
	padding-top:10px;
}
#content .box_member_register .edit{
	position:absolute;
	right:10px;
	bottom:10px;
}

/***************box_member_change_account****************/
#content .box_content.change_account{
	width:100%;
	margin:0px auto;
}

#content .box_content.change_account div{
	margin-top:10px;
}
#content .box_content.change_account .des{
	margin-top:-1px;
	border:1px solid #cecece;
	padding:10px !important;
}
#content .box_content.change_account label{
	display:block;
	width:100px;
	float:left;
	clear:left;
}
#content .box_content.change_account label span{
	color:red;
}
#content .box_content.change_account .info_mail{
	font-weight:bold;
}
#content .box_content.change_account .submit p{
	margin-left:100px;
	font-style:italic;
}
#content .box_content.change_account .submit span{
	color:red;
}
#content .box_content.change_account .submit input{
	background-image: url("img/button.png");
    background-position: -101px -132px;
    border:none;
    color: #FFFFFF;
    height: 24px;
    margin-bottom: 0;
    width: 97px;
	text-align:center;
	margin-left:100px;
	margin-top:10px;
	cursor:pointer;
}
#content .box_content.change_account .submit input:hover{
	background-image: url("img/button.png");
    background-position: -101px -156px;
}

/***************.box_content_change_address****************/
#content .box_content.change_address .des{
	border:1px solid #cecece;
	padding:10px;
	width:auto;
	margin:0px;
	margin-top:-1px;
}
#content .box_content.change_address{
	width:100%;
	margin:0px auto;
}
#content .box_content.change_address div{
	margin-top:10px;
}
#content .box_content.change_address label{
	width:100px;
	display:block;
	float:left;
	clear:left;
}
#content .box_content.change_address label span{
	color:red;
}
#content .box_content.change_address .submit p{
	margin-left:100px;
	font-style:italic;
}
#content .box_content.change_address .submit span{
	color:red;
}
#content .box_content.change_address .submit input{
	background-image: url("img/button.png");
    background-position: -101px -132px;
    border:none;
    color: #FFFFFF;
    height: 24px;
    margin-bottom: 0;
    width: 97px;
	text-align:center;
	margin-left:100px;
	margin-top:10px;
	cursor:pointer;
}
#content .box_content.change_address .submit input:hover{
	background-image: url("img/button.png");
    background-position: -101px -156px;
}

/****. history_payment ****/
.box_content.history_payment table{
	width: 100%;
}
.box_content.history_payment table td,
.box_content.history_payment table th{
	border: 1px solid #aaa;
	padding: 0 3px;
	text-align:center;
}
.box_content.history_payment table tr td.notice{
	height:25px;
}
.box_content.history_payment table span.time{
	font-size: 10px;
	font-style: italic;
	display: block;
	line-height: 15px;
	color: #666
}
.box_content.history_payment table th{
	background: #eee;
	line-height: 28px;
}
.box_content.history_payment table .small{
	text-align: center;
	width: 1px;
	white-space: nowrap;
}
.box_content.history_payment table.info th{
	width: 150px;
	text-align: left;
	background: none;
}
.box_content.history_payment h3{
	margin: 15px 0px 5px 0px;

}
.box_content.history_payment .detail_order p.old{
	text-decoration:line-through;
	color:#555555;
}
.box_content.history_payment .detail_order p.promotion{
	color:red;
}
.box_content.history_payment table tr.total th{
	background:none;
	text-align:right;
	border:none;
	color:red;
}
.box_content.history_payment table tr.total td{
	border:none;
	color:red;
	font-weight:bold;
	text-align:center;
}
/**.management_notice**/
.box_content.history_payment.management_notice tr td.No_Read a{
	font-weight:bold;
}

/***** .list_category *****/
#content .list_category {
	margin: 8px 0;
	padding: 8px;
	overflow: hidden;
	border: 1px solid #E8E8E8;
	background-color: #f9f9f9;
	border-radius: 5px;
	overflow: hidden;
}

#content .list_category li {
	list-style: none;
	width: 186px;
	float: left;
/*	border-bottom: 1px dotted #ddd;*/
	padding: 3px 0;
	margin-right: 4px;
}

#content .list_category li .thumb {
	float: left;
	margin-right: 10px;
}
#content .list_category .thumb a{
	line-height: 1px;
	display: block;
}
#content .list_category li a {
	line-height: 29px;
	color: #333;
}

/***** .list_category2 *****/
#content .list_category2{
	list-style: none;
	margin: 0;
	padding: 0;
}
#content .list_category2 li{
	float: left;
	width: 173px;
	text-align: center;
	height: 192px;
	overflow: hidden;
}
#content .list_category2 li a{
	color: #333;
}
#content .list_category2 li a.thumb{
	display: block;
	margin-bottom: 5px;
}


/***** .list_document *****/
#content .box_content ul.list_document{
	list-style: none;
	margin: 0;
	padding: 0;
}
#content .box_content ul.list_document li{
	display: block;
	padding: 5px;
}
#content .box_content ul.list_document a{
	color: #333;
}
#content .box_content ul.list_document li a.name{
	margin-left: 5px;
}
#content .box_content ul.list_document li a.more{
	margin-left: 8px;
}
#content .box_content ul.list_document li.bold{
	background-color: #eee;
}
#content .box_content ul.list_document .detail{
/*	display: none;*/
}
#content .box_content ul.list_document .left{
	float: left;
}
#content .box_content ul.list_document .right{
	float: right;
}
#content .box_content ul.list_document .summary{
	overflow: hidden;
}
#content .box_content ul.list_document .detail{
	border-top: 1px solid #ddd;
	margin-top: 8px;
	padding: 3px 0;
	font-size: 0.9em;
	display: none;
	color: #555;
	text-align: justify;
}


/***** .operation *****/
#content .box_content.operation{
	// width: 345px;
	// float: left;
}
#content .box_content.operation .title{
	border-bottom: 1px solid #cc3333;
	text-transform: uppercase;
	background: url(img/linhvuc.png) left 0px no-repeat;
	height: 35px;
	padding-left: 35px;
}
#content .box_content.operation .title a{
	float: none;
	color: #cc3333;
}
#content .box_content.operation ul{
	list-style: none;
	margin: 0;
	padding: 0;
}
#content .box_content.operation ul li{
	float: left;
	width: 150px;
	margin: 0 5px;
	background: url(img/arrow5.png) left 8px no-repeat;
	padding: 5px 0 5px 12px;
	border-bottom: 1px solid #eee;
}
#content .box_content.operation ul li a{
	color: #333;
}

/***** .realEstate *****/
#content .box_content.realEstate{
	// width: 345px;
	// float: right;
}
#content .box_content.realEstate .title{
	border-bottom: 1px solid #cc3333;
	text-transform: uppercase;
	background: url(img/linhvuc.png) left 0px no-repeat;
	height: 35px;
	padding-left: 35px;
}
#content .box_content.realEstate .title a{
	float: none;
	color: #cc3333;
}
#content .box_content.realEstate ul{
	list-style: none;
	margin: 0;
	padding: 0;
	float: left;
	width: 172px;
}
#content .box_content.realEstate ul li{

	margin: 0 5px;
	background: url(img/arrow5.png) left 8px no-repeat;
	padding: 5px 0 5px 12px;
	border-bottom: 1px solid #eee;
}
#content .box_content.realEstate ul li a{
	color: #333;
}


/***** .post1 *****/
#content .box_content.post1{
	width: 332px;
	height: 307px;
	overflow: hidden;
	float: right;
	background: url(img/post1.png) top center no-repeat;
	padding: 10px;
}
#content .box_content.post1 .title{
	padding-left: 36px;
	color: #cc3333;
	text-transform: uppercase;
}
#content .box_content.post1 .box_post{
	width: 330px !important;
	background: url(img/arrow3.png) left 5px no-repeat;
	padding-left: 9px;
	padding-bottom: 0;
}
#content .box_content.post1 .box_post a.name{
	font-weight: normal;
}
#content .box_content.post1 .box_post.first{
	background: none;
	padding: 0;
}
#content .box_content.post1 .box_post.first a.name{
	font-weight: bold;
}
#content .box_content.post1 .thumb{

}


/***** .box_post *****/
#content .box_content .box_post {
	// width: 269px;
	padding: 0 10px 10px 0;
	float: left;
	position: relative;
	z-index: 1;
	margin: 5px 0;
}

#content .box_content .box_post .top,
#content .box_content .box_post .bottom{
	position: absolute;
	width: 100%;
	height: 5px;
	z-index: 10;
	background: transparent;
}

#content .box_content .box_post .top {
	top: -5px;
}

#content .box_content .box_post .bottom {
	bottom: -5px;
}

#content .box_content .box_post .thumb {
	float: left;
	margin: 0 5px 5px 0;
	border: 1px solid #eee;
	padding: 2px;
}

#content .box_content .box_post:hover .thumb {
	border: 1px solid #aaa;
}

#content .box_content .box_post a.name {
	font-weight: bold;
	line-height: 16px;
	color: #333;

	@media screen and (max-width: 991px){
		font-size: .9rem;
	}
}
#content .box_content .box_post p.datetime{
	font-size: 11px;
	color: #666;
	font-style: italic;
}
#content .box_content .box_post p {
	text-align: justify;
}

#content .box_content .box_post a.more {
	font-style: italic;
}


/***** .order *****/
#content .order header.title{
	margin-bottom: 10px;
}
#content .order .form{
	float: left;
	width: 400px;
	border: 1px solid #ddd;
	padding: 10px 62px;
}
#content .order .form form{
	margin-top: 10px;
}
#content .order .form .submit{
	text-align: center;
	margin-top: 10px;
}
#content .order .form .submit input{
	background: url(img/button.png) -202px -66px no-repeat;
	border: none;
	width: 111px;
	height: 28px;
	color: #fff;
	font-weight: bold;
}
#content .order .form .submit input:hover{
	background-position: -202px -94px;
	cursor: pointer;
}
#content .order .form label{
	display: block;
	font-size: 12px;
	font-weight: bold;
}
#content .order .form .row{
	margin-bottom: 5px;
}
#content .order .map {
	border: 1px solid #d9d9d9;
}
#content .order input.larger{
	width: 386px;
}
#content .order input.larger1{
	width: 312px;
}
#content .order textarea.medium{
	width: 386px;
}
#content .order .form .row span.t{
	font-weight: bold;
	margin-right: 10px;
	display: block;
	width: 80px;
	float: left;
}
#content .confirm .form .row{
	border-bottom: 1px dotted #ddd;
	padding-bottom: 5px;
	text-align: justify;
	overflow: hidden;
}

/*** .thank ***/
#content .order.thank .des{
	background: #f9f9f9;
	border-radius: 5px;
	padding: 20px 10px;
	border: 1px solid #eee;
	color: green;
	font-size: 14px;
}

/*** ..order_info ***/
#content .order .order_info{
	float: right;
	border: 1px solid #ddd;
	margin-right: 1px;
	width: 410px;
	font-size: 12px;
	overflow: hidden;
}
#content .order .order_info span.title{
	background: #333;
	color: #fff;
	font-weight: bold;
	display: block;
	text-align: center;
	font-size: 13px;
}
#content .order .order_info table{
	width: 100%;
}
#content .order .order_info table th{
	padding: 5px 0;
	text-align: left;
	background: #f5f5f5
}
#content .order .order_info table th{
	border-right: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 5px;
}
#content .order .order_info table .name{
	font-weight: bold;
}
#content .order .order_info table .status{
	color: green;
}
#content .order .order_info table .promotion{
	color: #ff0000;
	font-style: italic;
}
#content .order .order_info table .old{
	text-decoration: line-through;
	color: #555;
}
#content .order .order_info table .new{
	font-weight: bold;
}
#content .order .order_info table .discount{
	color: #ff0000;
	font-style: italic;
}
#content .order .order_info table .center{
	text-align: center;
}

#content .order .order_info table td{
	padding: 0 5px;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;
}
#content .order .order_info table th:last-of-type,
#content .order .order_info table td:last-of-type{
	border-right: none;
}
#content .order .order_info table tr.total th{
	text-align: right;
	background: none;
}
#content .order .order_info table tr.total th,
#content .order .order_info table tr.total td{
	border-top: 1px dotted #aaa;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	color: #ff0000;
	white-space: nowrap;
	border: none;
}

/***** .box_video *****/
#content .box_content .box_video {
	width: 244px;
	height: 120px;
	margin: 2px;
	float: left;
	position: relative;
	text-align: center;
	border: 1px solid #eee;
	padding: 5px;
}
#content .box_content .related .box_video{
	height: 130px;
}
#content .box_content .box_video:hover{
	border: 1px solid #aaa;
}
#content .box_content .box_video a{
	color: #333;
}
#content .box_content .box_video .top,#content .box_content .box_video .bottom
	{
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
	left: 0px;
}

#content .box_content .box_video .top {
	top: -5px;
}

#content .box_content .box_video .bottom {
	bottom: -5px;
}

#content .box_content .box_video .thumb {
	padding: 2px;
}

#content .box_content .box_video a.name {
	line-height: 16px;
}

#content .box_content .video_play {
	text-align: center;
	margin-bottom: 10px;
}

/***** .tooltip_content ******/
.tooltip_content {
	display: none;
}

#tooltipContent p.name {
	font-weight: bold;
	padding-bottom: 5px;
}

#tooltipContent p.price span.old {
	color: #999;
	text-decoration: line-through;
}

#tooltipContent p.price span.new {
	color: #ff0000;
	font-weight: bold;
}

#tooltipContent p.promotion {
	padding: 8px 0;
	margin: 5px 0;
	background: #f9f9f9;
	color: #ff0000;
	font-style: italic;
	border-bottom: 1px solid #eee;
	border-top: 1px solid #eee;
}

#tooltipContent .des2 {
	font-size: 12px;
	color: #444
}

/*** .social ****/
#content ul.social{
	margin: 10px 0;
	padding: 0;
	list-style: none;
	overflow: hidden;
}
#content ul.social li{
	display: block;
	float: left;
}
#content ul.social li.facebook{
	width: 100px;
}
#content ul.social li.linkedin{
	padding-right: 15px;
}

/****** .read ******/
#content .read .des {
	padding-top: 15px;
	text-align: justify;
}
#content .read .des table,
#content .read .des table th,
#content .read .des table td{
	border: 1px solid #aaa;
}
#content .read .des table th,
#content .read .des table td{
	padding: 3px 5px;
}
#content .read .des p{
	margin: 8px 0;
	line-height: 20px;
	color: #222;
}
#content .des a{
	color: blue;
}

/****** .related *******/
#content .box_content .related {
	margin-top: -1px;
}

#content .box_content .related span.title {
	display:block;
	font-weight: bold;
	font-size: 18px;
	background: transparent;
	padding: 0;
	color: #ff0000;
	height: auto;
	line-height: 15px;
	margin: 20px 0 10px;
}

#content .box_content .related ul {
	margin: 0 0 0 6px;
	padding: 0;
}

#content .box_content .related li {
	list-style: none;
}

#content .box_content .related li a {
	background: url(img/arrow2.png) left 6px no-repeat;
	padding-left: 8px;
	color: #333;
}

/****** .gallery ******/
#content .box_content .box_gallery {
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 0 3px #ddd;
	width: 257px;
	text-align: center;
	padding: 3px;
	float: left;
	margin: 3px;
}

#content .box_content .box_gallery:hover {
	border: 1px solid #aaa;
}

#content .box_content .box_gallery p.name {
	padding: 0 5px;
	height: 19px;
	overflow: hidden;
}
#content .box_content .box_gallery a{
	color: #333;
}

/****** .gallery_detail *****/
.detail_gallery #content #slideshow_image {
	position: relative;
	overflow: hidden;
	background: #000;
	margin-bottom: 10px;
}

.detail_gallery #content .galleria-stage {
	position: absolute;
	top: 10px;
	bottom: 60px;
	left: 10px;
	right: 10px;
	overflow: hidden;
}

.detail_gallery #content .galleria-thumbnails-container {
	height: 50px;
	bottom: 0;
	position: absolute;
	left: 10px;
	right: 10px;
	z-index: 2;
}

.detail_gallery #content .galleria-carousel .galleria-thumbnails-list {
	margin-left: 30px;
	margin-right: 30px;
}

.detail_gallery #content .galleria-thumbnails .galleria-image {
	height: 40px;
	width: 60px;
	background: #000;
	margin: 0 5px 0 0;
	float: left;
	cursor: pointer;
}

.detail_gallery #content .galleria-counter {
	position: absolute;
	bottom: 10px;
	left: 10px;
	text-align: right;
	color: #fff;
	font: normal 11px/1 arial, sans-serif;
	z-index: 2;
}

.detail_gallery #content .galleria-loader {
	background: #000;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	display: none;
	background: url(img/classic-loader.gif) no-repeat 2px 2px;
}

.detail_gallery #content .galleria-info {
	width: 50%;
	top: 15px;
	left: 15px;
	z-index: 2;
	position: absolute;
}

.detail_gallery #content .galleria-info-text {
	background-color: #000;
	background-color: rgba(0, 0, 0, .9);
	padding: 12px;
	display: none;
}

.detail_gallery #content .galleria-info-title {
	font: bold 12px/1.1 arial, sans-serif;
	margin: 0;
	color: #fff;
}

.detail_gallery #content .galleria-info-description {
	font: italic 12px/1.4 georgia, serif;
	margin: 0;
	color: #bbb;
}

.detail_gallery #content .galleria-info-title+.galleria-info-description
	{
	margin-top: 7px;
}

.detail_gallery #content .galleria-info-close {
	width: 9px;
	height: 9px;
	position: absolute;
	top: 5px;
	right: 5px;
	background-position: -753px -11px;
	opacity: .5;
	cursor: pointer;
	display: none;
}

.detail_gallery #content .galleria-info-link {
	background-position: -669px -5px;
	opacity: .8;
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-color: #000;
}

.detail_gallery #content .galleria-info-link:hover,.detail_gallery #content .galleria-info-close:hover
	{
	opacity: .5;
}

.detail_gallery #content .galleria-image-nav {
	position: absolute;
	top: 50%;
	margin-top: -15px;
	width: 100%;
	height: 31px;
	left: 0;
}

.detail_gallery #content .galleria-image-nav-left,.detail_gallery #content .galleria-image-nav-right
	{
	opacity: .7;
	cursor: pointer;
	width: 16px;
	height: 31px;
	position: absolute;
	left: 10px;
	z-index: 2;
}

.detail_gallery #content .galleria-image-nav-right {
	left: auto;
	right: 10px;
	background-position: -300px 0;
	z-index: 2;
}

.detail_gallery #content .galleria-image-nav-left:hover,.detail_gallery #content .galleria-image-nav-right:hover
	{
	opacity: .5;
}

.detail_gallery #content .galleria-thumb-nav-left,.detail_gallery #content .galleria-thumb-nav-right
	{
	cursor: pointer;
	display: none;
	background-position: -495px 5px;
	position: absolute;
	left: 0;
	top: 0;
	height: 40px;
	width: 23px;
	z-index: 3;
	opacity: .8;
}

.detail_gallery #content .galleria-thumb-nav-right {
	background-position: -578px 5px;
	border-right: none;
	right: 0;
	left: auto;
}

.detail_gallery #content .galleria-thumbnails-container .disabled,.detail_gallery #content .galleria-thumbnails-container .disabled:hover
	{
	opacity: .2;
	cursor: default;
}

.detail_gallery #content .galleria-thumb-nav-left:hover,.detail_gallery #content .galleria-thumb-nav-right:hover
	{
	opacity: 1;
	background-color: #111;
}

.detail_gallery #content .galleria-carousel .galleria-thumb-nav-left,.detail_gallery #content .galleria-carousel .galleria-thumb-nav-right
	{
	display: block;
}

.detail_gallery #content .galleria-thumb-nav-left,.detail_gallery #content .galleria-thumb-nav-right,.detail_gallery #content .galleria-info-link,.detail_gallery #content .galleria-info-close,.detail_gallery #content .galleria-image-nav-left,.detail_gallery #content .galleria-image-nav-right
	{
	background-image: url(img/classic-map.png);
	background-repeat: no-repeat;
}

/****** .box_content_faq ******/
#content .box_content_faq ul.question {
	margin-top: 5px;
}

#content .box_content_faq ul.question li {
	list-style: decimal;
}

#content .box_content_faq h2 {
	line-height: 35px;
}

#content .box_content_faq .answer {
	margin-bottom: 10px;
	text-align: justify;
}
#content .box_content_faq .line{
	border-bottom: 1px solid #ddd;
	margin: 7px 0 5px;
}
#content .box_content_faq .highline{
	background: yellow;
	display: inline;
}

/****** .contact ******/
.contact #content .des{
	padding-top: 10px;
}
// .contact #content .info {
// 	float: left;
// 	width: 306px;
// 	margin-right: 22px;
// }
.contact #content .info table,
.contact #content .info table td,
.contact #content .info table th{
	border: none;
}
// .contact #content .info address{
// 	height: 182px;
// 	margin-bottom: 10px;
// 	text-align: left;
// }
.contact #content .form{
	padding: 10px 15px 10px;
	float: left;
	border: 1px solid #ddd;
	background: #FCFCFC;
	box-shadow: 0 0 3px #ddd;
	// width: 318px;
}
.contact #content .form .title{
	background: none;
	padding: 0;
	color: #E20909;
	font-style: italic;
	font-size: 12px;
	line-height: 15px;
}
.contact #content .form form{
	margin-top: 10px;
}
.contact #content .form .submit{
	text-align: center;
	margin-top: 10px;
}
.contact #content .form .submit input{
	background: url(img/button.png) 0px -132px no-repeat;
	border: none;
	width: 80px;
	height: 28px;
	padding-right: 15px;
	color: #fff;
	font-weight: bold;
}
.contact #content .form .submit input:hover{
	background-position: -0px -160px;
	cursor: pointer;
}
.contact #content .form label{
	display: block;
	font-size: 12px;
	font-weight: bold;
}
.contact #content .form .row{
	margin-bottom: 5px;
}
.contact #content .map {
	border: 1px solid #d9d9d9;
}
.contact #content input.larger{
	width: 306px;
}
.contact #content input.larger1{
	width: 232px;
}
.contact #content textarea.medium{
	width: 306px;
}



/****** .detail_product ******/
.detail_product #content .info {
	text-align: justify;
	padding-left: 385px;
}

.detail_product #content .info span.title {
	background: transparent;
	font-size: 20px;
	padding: 0 0 0 2px;
}
.detail_product #content .info hr{
	border: none;
	border-bottom: 2px solid #ddd;
	margin: 10px 0;
}

.detail_product #content .info table th {
	width: 105px;
}

.detail_product #content .info table th,.detail_product #content .info table td
	{
	padding: 3px 0;
	border-bottom: 1px dotted #f9f9f9;
}

.detail_product #content .info table tr.price span.old {
	text-decoration: line-through;
	color: #999;
}

.detail_product #content .info table tr.price span.new {
	color: #ff0000;
	font-weight: bold;
	font-size: 14px;
}
.detail_product #content .info span.save{
	font-style: italic;
	color: green;
}

.detail_product #content .info table tr.tag a {
	color: blue;
}

.detail_product #content .info table tr.promotion td {
	font-style: italic;
	color: #ff0000;
}
.detail_product #content .info table tr.status td{
	color: green;
}

.detail_product #content .info a.cart {
	display: block;
	width: 106px;
	padding-right: 15px;
	height: 33px;
	line-height: 33px;
	text-align: center;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	background-image: url(img/button.png);
	background-position: 0 -198px;
	margin-bottom: 15px;
}

.detail_product #content .info a.cart:hover {
	text-decoration: none;
	background-position: 0 -231px;
}

.detail_product #content .support span.title {
	font-weight: bold;
	font-size: 14px;
	padding: 0;
}

.detail_product #content .support {
	background: #f9f9f9;
	margin: 0 0 10px 0;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.detail_product #content .support ul {
	margin: 3px 0 0 0;
	padding: 0;
	overflow: hidden;
}

.detail_product #content .support ul li {
	list-style: none;
	float: left;
	display: block;
	width: 224px;
	padding-bottom: 6px;
}

.detail_product #content .support ul p {
	float: left;
	padding-right: 10px;
}

/*** .detail_product #content .product_des ****/
.detail_product #content .product_des {
	margin-top: 10px;
	overflow: hidden;
}

.detail_product #content .product_des .tab_container {
	padding: 10px;
}

.detail_product #content .tab_content .box_product {
	width: 238px;
	font-size: 13px;
}
/****** .register ******/
.registration .des{
	border:1px solid #cecece;
	margin-top:-1px;
	padding:10px !important;
}
.registration .form_register{
	width:100%;
}
.registration .form_register div{
	margin:10px auto;
}
.registration .form_register div label{
	width:150px;
	display:block;
	float:left;
	clear:left;
	line-height:25px;
}
.registration .form_register div label.error{
	margin-left:200px;
}

.registration .form_register .submit input{
	background-image: url("img/button.png");
    background-position: -101px -132px;
    border: medium none;
    color: #FFFFFF;
    display: inline;
    height: 24px;
    line-height: 24px;
    margin-right: 15px;
    padding: 0;
    text-align: center;
    width: 97px;
	margin-left:150px;
	margin-top:10px;
	float:left;
	clear:left;
	cursor:pointer;
}
.registration .form_register .submit input:hover{
	background-position: -101px -156px;
}

/****** .forget_password ******/
.forget_password .des{
	border:1px solid #cecece;
	padding:10px !important;
	margin-top:-1px;
}
.forget_password .row{
	margin:10px 0px;
}
.forget_password label{
	width:150px;
	display:block;
	float:left;
	clear:left;
}
.forget_password .row input{
	width:309px;
}
.forget_password .submit input{
	background-image: url("img/button.png");
    background-position: -101px -132px;
    border: medium none;
    color: #FFFFFF;
    display: inline;
    height: 24px;
    line-height: 24px;
    margin-right: 15px;
    padding: 0;
    text-align: center;
    width: 97px;
	margin-left:150px;
	float:left;
	clear:left;
	cursor:pointer;
}
.forget_password .submit input:hover{
	background-position: -101px -156px;
}
/*** .star_rate ***/
#content .bg_star_rate .star_rate{
	float: left;
	margin-right: 10px;
}
#content .bg_star_rate p.c{
	line-height: 27px;
	font-style: italic;
}
#content .star_rate ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#content .star_rate,#content .star_rate_2
	{
	background-image: url(img/star.gif);
	height: 23px;
}

#content .star_rate {
	width: 124px;
	overflow: hidden;
	position: relative;
	background-position: left top;
	margin-bottom: 5px;
}
#content .star_rate p{
	margin: 0 !important;
}

#content .star_rate_2 {
	background-position: 0 -24px;
	position: absolute;
}

#content .star_rate_3 {
	position: absolute;
}

#content .star_rate_3 a {
	position: absolute;
	top: -1px;
}

#content .star_rate_3 a:hover {
	display: block;
	background-image: url(img/star.gif);
	background-position: 0 -48px;
}

#content .star_rate_3 a {
	text-decoration: none;
	height: 23px;
	z-index: 10;
}

#content .star_rate_3 a.star1 {
	width: 25px;
	z-index: 8;
}

#content .star_rate_3 a.star2 {
	width: 50px;
	z-index: 6;
}

#content .star_rate_3 a.star3 {
	width: 75px;
	z-index: 4;
}

#content .star_rate_3 a.star4 {
	width: 100px;
	z-index: 2;
}

#content .star_rate_3 a.star5 {
	width: 125px;
	z-index: 1;
}

/*** .tabs ***/
#content ul.tabs {
	float: left;
	height: 26px; /*--Set height of tabs--*/
	margin: 0;
	padding: 0;
}

#content ul.tabs li {
	float: left;
	height: 25px;
	line-height: 25px; /*--Vertically aligns the text within the tab--*/
	border: 1px solid #cfcfcf;
	margin-bottom: -1px; /*--Pull the list item down 1px--*/
	overflow: hidden;
	position: relative;
	background: #e0e0e0 url('img/tab.png') repeat-x;
	margin-right: 2px;
}

#content ul.tabs li a,#content ul.tabs li {
	border-radius: 5px 5px 0 0;
}

#content ul.tabs li a {
	text-decoration: none;
	color: #000;
	display: block;
	font-size: 13px;
	padding: 0 20px;
	border: 1px solid #fff;
	outline: none;
}

#content ul.tabs li a:hover {
	background: #fff url(img/tab_active.png) repeat-x;
}

#content ul.tabs li.active,
#content ul.tabs li.active a:hover {
	/*--Makes sure that the active tab does not listen to the hover properties--*/
	background: url(img/tab_active.png) repeat-x;
	border-bottom: 1px solid #fff;
	/*--Makes the active tab look like it's connected with its content--*/
	font-weight: bold;
}

#content .tab_container {
	border: 1px solid #cfcfcf;
	overflow: hidden;
	clear: both;
	float: left;
	margin-bottom: 8px;
}

/*** .detail_product #content .gallery  ****/
.detail_product #content .gallery {
	float: left;
	margin: 0 12px 12px 0;
	position: relative;
}

.detail_product #content .gallery:hover a.previous,.detail_product #content .gallery:hover a.next
	{
	display: block;
}

.detail_product #content .gallery a.previous,.detail_product #content .gallery a.next{
	display: none;
	width: 25px;
	height: 50px;
	background-image: url(img/icon.png);
	position: absolute;
	top: 135px;
	z-index: 100;
}

.detail_product #content .gallery a:hover {
	text-decoration: none;
}

.detail_product #content .gallery a.previous {
	left: 4px;
	background-position: 0 -34px;
}

.detail_product #content .gallery a.next {
	right: 4px;
	background-position: -35px -34px;
}

.detail_product #content .gallery a.previous:hover {
	background-position: -61px -34px;
}

.detail_product #content .gallery a.next:hover {
	background-position: -96px -34px;
}

/* Etalage container (large thumb + small thumbs): */
#thumb_img {
	display: none;
}

/* Large thumbnail: */
#thumb_img .etalage_thumb {
	background: white url(img/loading.gif) center no-repeat;
	border: 2px solid #ddd;
	padding: 3px;
}

/* Large thumbnail - image (in case you want to add a border around the image within the frame) */
#thumb_img .etalage_thumb_image {

}

/* Small thumbnails: */
#thumb_img .etalage_small_thumbs li {
	background: white;
	border: 1px solid #ddd;
	margin: 10px;
	padding: 3px;
}

/* The one on the left that makes them slide */
#thumb_img ul li.etalage_smallthumb_first {

}

/* The one on the right that makes them slide */
#thumb_img ul li.etalage_smallthumb_last {

}

/* The currently active one */
#thumb_img ul li.etalage_smallthumb_active {
	box-shadow: 0 0 10px #ddd;
}

/* Zoomed image area: */
#thumb_img .etalage_zoom_area,.etalage_zoom_area {
	background: white url(img/loading.gif) center no-repeat;
	border: 1px solid #ddd;
	padding: 6px;
	box-shadow: 0 0 10px #ddd;
}

/* Magnifier area (thumbnail hovering rectangle): */
#thumb_img .etalage_magnifier {
	background: white;
	border: 1px solid #bbb;
}

/* Icon that will apear at the left bottom of the large thumbnail (optional): */
#thumb_img .etalage_icon {
	background: url(img/zoom.png) no-repeat;
	width: 14px;
	height: 14px;
	display: none !important;
}

/* Hint that will apear at the top right of the large thumbnail (optional): */
#thumb_img .etalage_hint { /*	background: url(img/hint.gif) no-repeat;*/
	width: 130px;
	height: 57px;
}

/* Description area (optional) */
#thumb_img .etalage_description {
	background: #333;
	font-style: italic;
	margin: 10px;
	padding: 6px 10px;
	color: #fff;
	border-radius: 20px;
	box-shadow: 0 0 3px #ddd;
}

/*
 * ------------------------------------ FUNCTIONALITY --------------------------------------
 * The following CSS serves to make Etalage function properly. Don't edit or edit carefully.
 * -----------------------------------------------------------------------------------------
 */
.etalage,.etalage_thumb,.etalage_thumb_image,.etalage_source_image,.etalage_zoom_preview,.etalage_icon,.etalage_hint
	{
	display: none
}

.etalage,.etalage ul,.etalage li,.etalage img,.etalage_hint,.etalage_icon,.etalage_description
	{
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none
}

.etalage,.etalage_magnifier div,.etalage_magnifier div img,.etalage_small_thumbs ul,ul .etalage_small_thumbs li,.etalage_zoom_area div,.etalage_zoom_img
	{
	position: relative
}

.etalage,.etalage_small_thumbs li {
	float: left
}

.etalage_right {
	float: right
}

.etalage li {
	position: absolute;
	z-index: 100;
}

.etalage img {
	vertical-align: bottom;
	max-width: none
}

.etalage_magnifier {
	cursor: default
}

.etalage_magnifier div,.etalage_small_thumbs {
	overflow: hidden
}

.etalage_magnifier div img {
	display: none
}

.etalage_icon,.etalage_hint {
	cursor: default;
	width: 0;
	height: 0;
	overflow: hidden
}

.etalage_small_thumbs li.vertical {
	float: none
}

.etalage_zoom_area div {
	overflow: hidden;
	z-index: 997
}

.etalage_zoom_preview {
	position: absolute;
	z-index: 998
}

.etalage_zoom_img,.etalage_hint {
	z-index: 999
}

.etalage {
	direction: ltr
}

div.etalage_description {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999
}

div.etalage_description.rtl {
	direction: rtl;
	text-align: right
}

/*** zoom popup ***/
.detail_product #content .gallery .zoom{
	position: absolute;
	top: 290px;
	right: 5px;
	z-index: 100;
	background: url(img/icon.png) -3px -171px no-repeat;
	display: block;
	width: 35px;
	height: 33px;
}
.detail_product #content .gallery .zoom:hover{
	background-position: -39px -171px;
}


/******** #comment ********/
#comment{
	margin-top: 15px;
}
#comment .title2{
	position: relative;
	height: 32px;
	border-bottom: 2px solid #ddd;
}
#comment .title2 span{
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	color: #ff0000;
}
#comment .title2 a.write{
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 0;
	background: url(img/button.png) -202px -132px no-repeat;
	width: 121px;
	text-align: center;
	color: #333;
}
#comment .title2 a.write:hover{
	background-position: -202px -158px;
	text-decoration: none;
}
#comment ul.comment{
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
}
#comment ul.comment li{
	border-bottom: 1px solid #ddd;
	padding: 10px 0;
	text-align: justify;
}
#comment ul.comment li p.title{
	background: transparent;
	padding: 0;
	font-weight: bold;
	height: 25px;
}
#comment ul.comment li p.name{
	color: #333;
	font-weight: bold;
}
#comment ul.comment li p.name span{
	font-size: 11px;
	color: #444;
}
#comment ul.comment ul{
	background: #F4F4F4;
	padding-left: 25px;
	margin-top: 8px;
	padding-right: 10px;
	margin-bottom: 6px;
}
#comment ul.comment ul li:last-of-type{
	border-bottom: none;
}
#comment span.title3{
	font-size: 18px;
	line-height: 25px;
	font-weight: bold;
	display: block;
	border-bottom: 2px solid #ddd;
	color: #FF0000;
	margin-top: 20px;
}
#comment table{
	margin-top: 20px;
}
#comment table th{
	text-align: left;
	width: 110px;
}
#comment table th,
#comment table td{
	padding-bottom: 8px;
}
#comment table .submit button{
	background: #eee url(img/button.png) -202px -66px no-repeat;
	border: none;
	height: 28px;
	width: 111px;
	color: #fff;
}
#comment table .submit button:hover{
	background-position: -202px -94px;
	cursor: pointer;
}
#comment p.button{
	text-align: right;
}
#comment p.button a.answer{
	font-size: 12px;
	border-radius: 3px 0 3px 0;
	margin-right: 10px;
	color: #E20808;
}
#comment p.button a.like{
	font-size: 12px;
	margin-right: 10px;
	font-weight: bold;
	color: #333;
}
#comment p.button a.like.no_like{
	font-weight: normal;
}
#comment ul li li p.button a.like{
	margin-right: 0;
}
#comment p.button a.like span{
	margin: 0 3px;
}
#comment p.button a.like span.like{
	background: url(img/icon.png) -164px -5px no-repeat;
	padding: 2px 7px 0;
}
#comment p.button a.like:hover{
	text-decoration: none;
}


/******** #breadcrumb *******/
.home #breadcrumb{
	display: none;
}
#breadcrumb ul {
	margin: 3px 0 0 0;
	padding: 0 0 0 5px;
	font-size: 12px;
	float: left;
	color: #555
}

#breadcrumb ul li {
	display: block;
	float: left;
	margin-right: 5px;
	position: relative;
}

#breadcrumb ul li a {
	background: url(img/c_breadcrumb.png) right 5px no-repeat;
	padding-right: 10px;
	color: #555
}

#breadcrumb ul li.home {
	background: url(img/c_home.png) center left no-repeat;
}

#breadcrumb ul li.home a {
	padding: 0 12px;
}

#breadcrumb ul li ul {
	display: none;
	position: absolute;
	top: 15px;
	background: #f9f9f9;
	z-index: 1000;
	box-shadow: 0 0 2px #222;
}

#breadcrumb ul li:hover ul {
	display: block;
}

#breadcrumb ul li ul li {
	width: 200px;
	padding: 3px 0;
	display: block;
	margin: 0;
	border-bottom: 1px solid #eee;
	background: url('img/arrow1.png') 4px 10px no-repeat;
}
#breadcrumb ul li ul li:hover{
	background-position: 5px 10px;
}

#breadcrumb ul li ul li a {
	background: none;
	padding: 0 0 0 10px;
}

/********* ul.step *********/
#content ul.step {
	margin: 0;
	padding: 0;
	float: left;
	width: 100%;
	margin-bottom: 15px;
	border-collapse: collapse;
}

#content ul.step li {
	display: block;
	float: left;
	width: 25%;
	line-height: 48px;
	font-size: 16px;
	font-weight: bold;
	color: #444;
}

#content ul.step li span {
	font-size: 25px;
	padding: 0 12px;
	display: block;
	width: 25px;
	margin-right: 5px;
	float: left;
	background: url(img/button.png) -151px -49px no-repeat;
	color: #fff;
}

#content ul.step li.active span {
	background-position: -151px 0;
}

#content ul.step li.active {
	color: #ff0000;
}

/***** #back-top  ****/
#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 9999;
}

#back-top a {
	color: #BBBBBB;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 12px;
	line-height: 26px;
	display: block;
	text-align: center;
}

#back-top a:hover {
	color: #000000;
}

#back-top span {
	background: #888 url("img/up-arrow.png") no-repeat scroll center center;
	border-radius: 5px;
	width: 45px;
	height: 45px;
	display: block;
	margin-bottom: 7px;
}

#back-top a:hover span {
	background-color: #666;
}


/**** #adv_left_out #adv_right_out ****/
#adv_left_out,
#adv_right_out{
	position: fixed;
	top: 5px;
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 2px;
	width: 149px;
	text-align: center;
}
#adv_left_out{
	left: 10px;
}
#adv_right_out{
	right: 10px;
}



/******** #cart *******/
#cart{
	position: fixed;
	bottom: 0;
	right: 15px;
	width: 200px;
	background: #fff;
	border: 1px solid #ff0000;
	z-index: 1000;
	overflow: hidden;
	border-radius: 2px 2px 0 0;
	text-align: center;
	box-shadow: 0px -1px 3px #aaa;
}
#cart a.title{
	background: url(img/pupup_cart.png) center top repeat-x;
	color: #fff;
	font-weight: bold;
	padding: 0;
	line-height: 25px;
	display: block;
	text-transform: uppercase;
	font-size: 12px;
}

#cart a.title:hover{
	text-decoration: none;
}

#cart div{
	line-height: 22px;
	padding: 6px;
	display: none;
}
#cart p.link a{
	color: #ff0000;
}


/******** #support_2 *******/
#support_2{
	position: fixed;
	bottom: 0;
	right: 15px;
	width: 200px;
	background: #fff;
	border: 1px solid #006cb5;
	z-index: 10000;
	border-radius: 2px 2px 0 0;
	text-align: center;
	box-shadow: 0px -1px 3px #aaa;
}
#support_2 span.icon{
	background: url(img/icon_support.png) no-repeat;
	width: 48px;
	height: 48px;
	position: absolute;
	top: -15px;
	left: 2px;
}
#support_2 a.title{
	background: url(img/pupup_support.png) center top repeat-x;
	color: #fff;
	font-weight: bold;
	padding: 0;
	line-height: 25px;
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	padding-left: 20px;
}
#support_2 a.title:hover{
	text-decoration: none;
}
#support_2 div{
	line-height: 22px;
	padding: 15px 6px 10px;
	display: none;
	overflow: hidden;
}
#support_2 ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
#support_2 ul li{
	padding: 3px 0;
	overflow: hidden;
}
#support_2 ul p{
	float: left;
}
#support_2 ul p.name{
	text-align: left;
	width: 147px;
}
#support_2 ul p.nick{
	width: 20px;
}
#support_2 ul a:hover{
	text-decoration: none;
}

/*** popup ****/
#mask {
	position:fixed;
	left:0;
	top:0;
	z-index:10000;
	background-color:#000;
	display:none;
	opacity: 0.8;
}
#popup .window {
	position:absolute;
	left:0;
	top: 100px;
	width:820px;
	display:none;
	z-index:9999;
	color: #fff;
	width: 500px;
	text-align: justify;
	z-index: 11000;
}
#popup a.close{
	padding: 5px 12px;
	float: right;
	margin-right: -13px;
	margin-top: -13px;
	background: url(img/icon.png) -92px 0 no-repeat;
}
#popup a.close:hover{
	background-position: -122px 0;
	text-decoration: none;
}


/**** #popup_cart *****/
#popup #popup_cart{
	background: #eee;
	height: 400px;
	width: 700px !important;
	color: #333;
	border-radius: 2px;
	overflow: hidden;
}
#popup #popup_cart span.title{
	color: #fff;
	font-weight: bold;
	font-size: 15px;
	line-height: 32px;
	background: #D64937;
	display: block;
	padding-left: 10px;
	text-transform: uppercase;
}
#popup #popup_cart span.title span{
	padding: 4px 18px;
	background: url(img/icon.png) -185px -2px no-repeat;
	margin-right: 5px;
}
#popup #popup_cart .list_product{
	padding: 0 10px;
	height: 250px;
	overflow: auto;
	background: #fff;
	border-bottom: 1px solid #ddd;
}
#popup #popup_cart .list_product .new{
	font-weight: bold;
}
#popup #popup_cart .list_product .old{
	text-decoration: line-through;
	font-size: 12px;
}
#popup #popup_cart .list_product .discount{
	color: #ff0000;
	font-size: 12px;
	font-style: italic;
}
#popup #popup_cart table{
	margin-top: 15px;
	width: 100%;
	border: 1px solid #ddd;
}
#popup #popup_cart table .small{
	width: 1px;
	white-space: nowrap;
}
#popup #popup_cart table .center{
	text-align: center;
}
#popup #popup_cart .list_product table img{
	margin: 0 5px 2px 0;
}
#popup #popup_cart .list_product table .name{
	font-weight: bold;
}
#popup #popup_cart .list_product table p.status{
	color: green;
}
#popup #popup_cart .list_product table .promotion{
	color: #ff0000;
	font-style: italic;
}
#popup #popup_cart table th{
	line-height: 20px;
	background: #eee;
}
#popup #popup_cart table th,
#popup #popup_cart table td{
	border-bottom: 1px solid #ddd;
	padding: 6px;
}
#popup #popup_cart table td.price{
	font-weight: bold;
}
#popup #popup_cart .payment{
	width: 200px;
	float: right;
	margin-top: 15px;
}
#popup #popup_cart .payment p.total{
	font-weight: bold;
	color: #D64937;
	font-size: 15px;
	padding-bottom: 12px;
}
#popup #popup_cart .payment p.total span{
	display: block;
	width: 70px;
	float: left;
}
#popup #popup_cart .payment .close{
	background: none;
	float: none;
	padding: 0;
	margin: 0;
	color: green;
}
#popup #popup_cart .payment .close a:hover{
	text-decoration: underline;
}
#popup #popup_cart .payment p.payment2 a{
	background: #D14836;
	color: #fff;
	display: block;
	width: 175px;
	text-align: center;
	line-height: 28px;
	font-weight: bold;
	margin-bottom: 5px;
	border-radius: 3px;

}
#popup #popup_cart .payment p.payment2 a:hover{
	background: #C43C2D;
	text-decoration: none;
}

#popup #popup_cart .empty_order{
	text-align: center;
	margin-top: 100px;
	font-size: 18px;
}

/*******#popup_member************/
#popup_member{
	background: #fff;
	min-height: 250px;
	width: 410px !important;
	border-radius:7px;
	position:relative;
}
#popup_member .popup-header{
	height:30px;
	background:#E30D0D;
	border-radius:7px 7px 0px 0px;
	line-height:30px;
	padding-left:27px;
	font-size:18px;
}
#popup_member .popup_form{
    width: 354px;
	margin:0px auto;
}
#popup_member .popup_form div{
	margin-top:10px;
}
#popup_member .popup_form div.error{
	height:13px;
	margin-top:0px;
}
#popup_member .popup_form div label{
	color: #666666;
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
    text-transform: uppercase;
	width:100px;
	display:block;
	margin-bottom:5px;
}
#popup_member .popup_form div.row input{
	border: 1px solid #CCCCCC;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1) inset;
    color: #cecece;
    font-family: arial;
    font-size: 125%;
    font-weight: bold;
    line-height: 18px;
    padding: 8px 6px 7px;
    width: 340px;
}
#popup_member .popup_form p{
	padding-top:5px;
	font-style:italic;
}
#popup_member .popup_form div.button input{
	background-image: url("img/button.png");
    background-position: -101px -132px;
    color: #FFFFFF;
    display: inline;
    line-height: 24px;
    text-align: center;
    width: 97px;
	border:none;
	margin-right:15px;
	padding:0px;
	height:24px;
	cursor:pointer;
}
#popup_member .popup_form div.button input:hover{
	background-position: -101px -156px;
}

#popup_member .popup_form .error_none{
	display:none;
}
#popup_member .popup_form .error_show{
	display:block;
	color:red;
	font-style:italic;
	text-align:center;
	position:absolute;
	top:35px;
	left:80px;
}

/**** #lock_web ****/
#lock_web{
	position: fixed;
	z-index: 11000;
	top: 0;
	color: #000;
	width: 100%;
	height: 100%;
}
#lock_web .mask{
	opacity: 0.7;
	background: #333;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
}
#lock_web .des{
	width: 600px;
	margin: 100px auto 0;
	background: #fff;
	border-radius: 6px;
	padding: 15px;
	font-size: 16px;
	line-height: 22px;
	min-height: 200px;
	max-height: 400px;
	position: relative;
	z-index: 12000;
	overflow: auto;
}


/**** #ajax_loading ****/
#message_top {
	display: none;
	top: 20px;
	float: right;
	position: fixed;
	z-index: 100000;
	width: 100%;
	text-align: center;
}
#message_top #loading{
	width: 160px;
	margin: 0 auto;
	padding: 30px 10px 15px;
	border-radius: 3px;
	box-shadow: 0px 0px 4px #333;
	opacity: 0.9;
	background: #fff url(img/ajax-loading.gif) center 10px no-repeat;
}


/**** paginator ****/
#content .paginator {
	text-align: right;
	height: 23px;
	line-height: 20px;
	display: block;
	padding-right: 5px;
	padding-top: 10px;
}
#content .paginator span{
	margin: 1px;
}
#content .paginator span a,
#content .paginator span.current{
	padding: 2px 5px;
	background: #eee;
	border-radius: 5px 0 5px 0; /* Opera 10.5, IE 9, Saf5, Chrome */;
	border: 1px solid #D3D3D3;
	color: #333;
}
#content .paginator span.current,
#content .paginator a:hover {
	font-weight: bold;
	border: 1px solid #C6C6C6;
	background: #ddd;
	color: #333;
}
#content .paginator a.disabled-page {
	color: #777;
}
#content .paginator a.disabled-page:hover {
	text-decoration: none;
	cursor: default;
}


/**** .box_banner ****/
.box_banner ul{
	list-style: none;
	padding: 0;
	margin: 0;
	overflow: hidden;
	width: 180px;
}
.box_banner ul li{
	float: left;
	border: 1px solid #ddd;
	margin: 2px 4px 2px 0;
}
.box_banner ul li:hover{
	border: 1px solid #999;
}

/**** .banner_bottom ****/
.box_banner.banner_bottom,
.box_banner.banner_bottom .caroufredsel_wrapper{
	width: 960px !important;
	overflow: hidden;
}
.box_banner.banner_bottom{
	margin-bottom: 8px;
}


/**** .box_partner ****/
.box_partner{
	margin-bottom: 18px;
	overflow: hidden;
	width: 960px;
}
.box_partner ul li{
	float: left;
	border: 1px solid #ddd;
	margin: 0 3px;
	display: block;
	height: 80px;
}
.box_partner ul li:hover{
	border: 1px solid #333;
}
.box_partner span.title{
	font-size: 15px;
	font-weight: bold;
	padding-left: 8px;
	line-height: 25px;
}
.box_partner .caroufredsel_wrapper{
	margin: 0 auto !important;
	height: 82px !important;
}
.box_partner ul{
	height: 82px;
}s

.simply-scroll-container { /* Container DIV - automatically generated */
	position: relative;
}

.simply-scroll-clip { /* Clip DIV - automatically generated */
	position: relative;
	overflow: hidden;
}

.simply-scroll-list { /* UL/OL/DIV - the element that simplyScroll is inited on */
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style: none;
}

.simply-scroll-list li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.simply-scroll-list li img {
	border: none;
}

.simply-scroll-btn {
	position: absolute;
	background-image: url(buttons.png);
	width: 42px;
	height: 44px;
	z-index:3;
	cursor: pointer;
}

.simply-scroll-btn-left {
	left: 6px;
	bottom: 6px;
	background-position: 0 -44px;
}
.simply-scroll-btn-left.disabled {
	background-position: 0 0 !important;
}
.simply-scroll-btn-left:hover, .simply-scroll-btn-left:focus {
	background-position: 0 -88px;
}

.simply-scroll-btn-right {
	right: 6px;
	bottom: 6px;
	background-position: -84px -44px;
}
.simply-scroll-btn-right.disabled {
	background-position: -84px 0 !important;
}
.simply-scroll-btn-right:hover, .simply-scroll-btn-right:focus {
	background-position: -84px -88px;
}

.simply-scroll-btn-up {
	right: 6px;
	top: 6px;
	background-position: -126px -44px;
}
.simply-scroll-btn-up.disabled {
	background-position: -126px 0 !important;
}
.simply-scroll-btn-up:hover, .simply-scroll-btn-up:focus {
	background-position: -126px -88px;
}

.simply-scroll-btn-down {
	right: 6px;
	bottom: 6px;
	background-position: -42px -44px;
}
.simply-scroll-btn-down.disabled {
	background-position: -42px 0 !important;
}
.simply-scroll-btn-down:hover, .simply-scroll-btn-down:focus {
	background-position: -42px -88px;
}

.simply-scroll-btn-pause {
	right: 6px;
	bottom: 6px;
	background-position: -168px -44px;
}
.simply-scroll-btn-pause:hover, .simply-scroll-btn-pause:focus {
	background-position: -168px -88px;
}

.simply-scroll-btn-pause.active {
	background-position: -84px -44px;
}
.simply-scroll-btn-pause.active:hover, .simply-scroll-btn-pause.active:focus {
	background-position: -84px -88px;
}

/* Custom class modifications - override classees

.simply-scroll is default

*/

.simply-scroll .simply-scroll-list {}

.simply-scroll .simply-scroll-list li {
	float: left;
	width: 110px;
	text-align: center;
}
.simply-scroll .simply-scroll-list li img {}

.simply-scroll .simply-scroll-btn {}

.simply-scroll .simply-scroll-btn-left {}
.simply-scroll .simply-scroll-btn-left.disabled {}
.simply-scroll .simply-scroll-btn-left:hover {}

.simply-scroll .simply-scroll-btn-right {}
.simply-scroll .simply-scroll-btn-right.disabled {}
.simply-scroll .simply-scroll-btn-right:hover {}

.simply-scroll .simply-scroll-btn-up {}
.simply-scroll .simply-scroll-btn-up.disabled {}
.simply-scroll .simply-scroll-btn-up:hover {}

.simply-scroll .simply-scroll-btn-down {}
.simply-scroll .simply-scroll-btn-down.disabled {}
.simply-scroll .simply-scroll-btn-down:hover {}



/* Vertical scroller example */

.vert { /* wider than clip to position buttons to side */
width: 340px;
	height: 400px;
	margin-bottom: 1.5em;
}

.vert .simply-scroll-clip {
	width: 290px;
	height: 400px;
}

.vert .simply-scroll-list {}

.vert .simply-scroll-list li {
	width: 290px;
	height: 200px;
}
.vert .simply-scroll-list li img {}

.vert .simply-scroll-btn {}

.vert .simply-scroll-btn-up { /* modified btn pos */
	right: 0;
	top: 0;
}
.vert .simply-scroll-btn-up.disabled {}
.vert .simply-scroll-btn-up:hover {}

.vert .simply-scroll-btn-down { /* modified btn pos */
	right: 0;
	top: 52px;
}
.vert .simply-scroll-btn-down.disabled {}
.vert .simply-scroll-btn-down:hover {}

/* NOTE left-right classes wouldn't be needed on vertical scroller */



/********* #slideshow **********/
#slideshow{
	margin-bottom: 12px;
}
#slideshow ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
#slideshow .wt-rotator{
	font-size:12px;
	background-color:#fff;
	position:relative;
	overflow:hidden;
	margin: 0 auto;
}
#slideshow .wt-rotator{
	line-height: 16px;
}
#slideshow .wt-rotator span.title{
	line-height: 25px;
	display: block;
	font-size: 15px;
	font-weight: bold;
}
#slideshow .wt-rotator a{
	outline:none;
}
#slideshow .wt-rotator .screen{
	position:relative;
	top:0;
	left:0;
	overflow:hidden;
}
#slideshow .wt-rotator #strip{
	display:block;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	overflow:hidden;
}
#slideshow .wt-rotator .content-box{
	display:none;
	position:absolute;
	top:0;
	left:0;
	overflow:hidden;
}
#slideshow .wt-rotator .main-img{
	display:none;
	position:absolute;
	top:0;
	left:0;
	z-index:0;
	border:0;
}
#slideshow .wt-rotator .preloader{
	position:absolute;
	top:50%;
	left:50%;
	width:36px;
	height:36px;
	margin-top:-18px;
	margin-left:-18px;
	border-radius:2px;
	background:#000 url(img/loader.gif) center no-repeat;
	background:rgba(0,0,0,.7) url(img/loader.gif) center no-repeat;
	z-index:4;
	display:none;
}
#slideshow .wt-rotator #timer{
	position:absolute;
	left:0;
	height:4px;
	background-color:#FFF;
	opacity:0.5;
	z-index:4;
	visibility:hidden;
	font-size:0;
}
#slideshow .wt-rotator .desc{
	color:#000;
	position:absolute;
	top:0;
	left:0;
	z-index:6;
	overflow:hidden;
	visibility:hidden;
	text-align:left;
}
#slideshow .wt-rotator .inner-bg{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:#000;
	opacity:0.7;
	z-index:1;
}
#slideshow .wt-rotator .inner-text{
	position:absolute;
	padding:10px;
	z-index:2;
}
#slideshow .wt-rotator .c-panel{
	position:absolute;
	top:0;
	z-index:7;
	visibility:hidden;
}
#slideshow .wt-rotator .outer-cp{
	position:absolute;
	left:0;
	width:100%;
	background:#333;
	border:1px solid #000;
	border-left:none;
	border-right:none;
}
#slideshow .wt-rotator .thumbnails,
#slideshow .wt-rotator .buttons{
	display:inline;
	position:relative;
	float:left;
	overflow:hidden;
}
#slideshow .wt-rotator .thumbnails li,
#slideshow .wt-rotator .play-btn,
#slideshow .wt-rotator .prev-btn,
#slideshow .wt-rotator .next-btn{
	list-style:none;
	display:inline;
	float:left;
	margin-right:4px;
	overflow:hidden;
	width:24px;
	height:24px;
	line-height:24px;
	text-align:center;
	color:#FFF;
	background-color:#333;
	cursor:pointer;
	font-weight:bold;
	border-radius: 10px;
}
#slideshow .wt-rotator .thumbnails li.thumb-over{
	color:#FFF;
	background-color:#888;
}
#slideshow .wt-rotator .thumbnails li.curr-thumb{
	color:#333;
	background-color:#fff;
	cursor:default;
}
#slideshow .wt-rotator .thumbnails li.image{
	background-color:#000;
}
#slideshow .wt-rotator .thumbnails li.image.curr-thumb,
#slideshow .wt-rotator .thumbnails li.image.thumb-over{
	border-color:#06F;
}
#slideshow .wt-rotator .thumbnails li.image a,
#slideshow .wt-rotator .thumbnails li.image img{
	display:block;
	border:0;
}
#slideshow .wt-rotator .thumbnails li.image.curr-thumb img{
	opacity:0.8;
	cursor:default;
}
#slideshow .wt-rotator .thumbnails li *{
	display:none;
}
#slideshow .wt-rotator .thumbnails li div{
	position:relative;
	color:#FFF;
	background-color:#000;
}
#slideshow .wt-rotator .play-btn{
	background:#333 url(img/play.png) no-repeat center;
}
#slideshow .wt-rotator .pause{
	background:#333 url(img/pause.png) no-repeat center;
}
#slideshow .wt-rotator .prev-btn{
	background:#333 url(img/prev.png) no-repeat center;
}
#slideshow .wt-rotator .next-btn{
	background:#333 url(img/next.png) no-repeat center;
}
#slideshow .wt-rotator .button-over{
	background-color:#888;
}
#rotator-tooltip{
	position:absolute;
	top:0;
	left:0;
	z-index:99999;
	display:none;
}
#rotator-tooltip.txt-up{
	margin-left:-10px;
	margin-bottom:5px;
	background:url(img/tail.png) no-repeat;
	background-position:10px bottom;
}
#rotator-tooltip.txt-down{
	margin-left:-10px;
	margin-top:24px;
	background:url(img/rev_tail.png) no-repeat;
	background-position:5px top;
}
#rotator-tooltip.img-up{
	background:url(img/tail.png) center bottom no-repeat;
}
#rotator-tooltip.img-down{
	background:url(img/rev_tail.png) center top no-repeat;
}
#rotator-tooltip .tt-txt{
	font-size:12px;
	color:#FFF;
	background-color:#000;
	max-width:300px;
	padding:4px;
	margin:8px 0;
}
#rotator-tooltip .tt-img{
	display:none;
	background-color:#000;
	padding:2px;
	margin:3px 0;
}
#slideshow .wt-rotator .block,
#slideshow .wt-rotator .vpiece,
#slideshow .wt-rotator .hpiece{
	position:absolute;
	z-index:2;
}


/********************************************************/
/********************** #footer *************************/
/********************************************************/
#bg_footer{
	background-color: #666666
}
#footer {
	// min-height: 150px;
	position: relative;
	// width: 960px;
	// margin: 0 auto;
}
#bg_footer p,
#bg_footer a,
#bg_footer span,
#bg_footer li{
	color: #eee;
}
#footer .top,#footer .bottom {
	position: absolute;
	width: 100%;
	height: 5px;
	background: transparent;
}

#footer .top {
	top: 0px;
}

#footer .bottom {
	bottom: 0px;
}

#footer address {
}
#footer .design{
	text-align: right;
	font-size: 11px;
	font-style: italic;
	position: absolute;
	bottom: 5px;
	right: 15px;
}
#footer .design span{
	color: #333;
	font-weight: bold;
}
#footer .design span span{
	color: #F6D901;
}

/***** .text_link *****/
.text_link{
	padding: 10px 0 10px;
	// width: 960px;
	// margin: 0 auto;
}

/***** .nav_top *****/
#footer .nav_top {
	text-align: center;
}

#footer .nav_top .left,#footer .nav_top .right,#footer .nav_top {
	height: 34px;
}

#footer .nav_top .left,#footer .nav_top .right {
	position: relative;
	top: 0;
	width: 5px;
}

#footer .nav_top .left {
	position: absolute;
	left: 0;
}

#footer .nav_top .right {
	position: absolute;
	right: 0;
}

#footer .nav_top ul {
	padding: 8px;
}
#footer .nav_top ul ul{
	display: none;
}

#footer .nav_top ul li {
	display: inline;
	padding: 5px 15px;
}
#footer .nav_top ul li a{
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
}

/**** .social ****/
#footer .social ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
#footer .social{
	// position: absolute;
	// top: 40px;
	// right: 15px;
}
#footer .social ul.link{
	margin-bottom: 5px;
}
#footer .social ul.link li{
	display: inline;
}


/**** .mesages_full ****/
.mesages_full{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(img/dot.png);
	z-index: 100000;
	padding-top: 200px;
}
.mesages_full div{
	width: 500px;
	margin: 0 auto;
	text-align: justify;
	background: #fff;
	font-size: 18px;
	padding: 15px;
	border-radius: 5px;
	line-height: 25px;
}
.mesages_full div span.title{
	display: block;
	margin-top: 10px;
}

/*** #skypedetectionswf ****/
#skypedetectionswf{
	position: absolute;
	bottom: 0;
	right: 0;
	width: 1px;
	height: 1px;
}

.sidebar .box span.title a {
    color: #fff;
}

