$hotline-color: red;

.hotline-alo-phone{
	display: none;
	position: fixed;
	right: 1rem;
	bottom: 20%;
	@media screen and (max-width: 768px) {

		bottom: 45px;
	}
	width: 145px;
	height: 145px;
	z-index: 999
}
.phonering-alo-ph-circle, .phonering-alo-ph-circle-fill, .phonering-alo-ph-img-circle{
	position: absolute;
	border-radius: 50% !important;
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%
}
.phonering-alo-ph-circle{
	width: 145px;
	height: 145px;
	background-color: transparent;
	border: 2px solid rgba(30,30,30,0.4);
	top: 0;
	left: 0;
	-webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
	animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
	transition: all .5s
}
.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle{
	border-color: #bfebfc;
	opacity: .5
}
.phonering-alo-ph-circle-fill{
	width: 85px; height: 85px;
 -webkit-animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
 animation: phonering-alo-circle-fill-anim 2.3s infinite ease-in-out;
 transition: all .5s
}
.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle-fill{
	background-color: rgba(0,175,242,0.5)
}
.phonering-alo-ph-img-circle{
	width: 45px;
	height: 45px;
	background: rgba(30,30,30,0.1) url(../../img/call.png) no-repeat center center;
	background-size: auto 80%;
	-webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
	animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
	z-index: 999
}
.phonering-alo-ph-img-circle a{
	display: block;
	width: 45px;
	line-height: 45px;
	text-indent: -9999px
}
.phonering-alo-ph-img-circle a:hover{
	background: rgba( 255, 255, 255, .2 )
}
.phonering-alo-ph-img-circle a:before{
	color: #fff;
	font-size: 30px;
	display: inline-block
}
.phonering-alo-phone:hover .phonering-alo-ph-img-circle{
	background-color: #00aff2
}
.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle{
	background-color: #00aff2
}
@-webkit-keyframes phonering-alo-circle-anim{
	0%{
		-webkit-transform:rotate(0) scale(.5) skew(1deg);
		-webkit-opacity:.1
	}
	30%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		-webkit-opacity:.5
	}
	100%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		-webkit-opacity:.1
	}
}
@-webkit-keyframes phonering-alo-circle-fill-anim{
	0%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
	50%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		opacity:.2
	}
	100%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
}
@-webkit-keyframes phonering-alo-circle-img-anim{
	0%{
		-webkit-transform:rotate(0) scale(1) skew(1deg)
	}
	10%{
		-webkit-transform:rotate(-25deg) scale(1) skew(1deg)
	}
	20%{
		-webkit-transform:rotate(25deg) scale(1) skew(1deg)
	}
	30%{
		-webkit-transform:rotate(-25deg) scale(1) skew(1deg)
	}
	40%{
		-webkit-transform:rotate(25deg) scale(1) skew(1deg)
	}
	50%{
		-webkit-transform:rotate(0) scale(1) skew(1deg)
	}
	100%{
		-webkit-transform:rotate(0) scale(1) skew(1deg)
	}
}
@-webkit-keyframes fadeInRight{
	0%{
		opacity:0;
		-webkit-transform:translate3d(100%, 0, 0);
		-ms-transform:translate3d(100%, 0, 0);
		transform:translate3d(100%, 0, 0)
	}
	100%{
		opacity:1;
		-webkit-transform:none;
		-ms-transform:none; transform:none
	}
}
@keyframes fadeInRight{
	0%{
		opacity:0;
		-webkit-transform:translate3d(100%, 0, 0);
		-ms-transform:translate3d(100%, 0, 0);
		transform:translate3d(100%, 0, 0)
	}
	100%{
		opacity:1;
		-webkit-transform:none;
		-ms-transform:none; transform:none
	}
}
@-webkit-keyframes fadeOutRight{
 	0%{
 		opacity:1
	}
	100%{
		opacity:0;
		-webkit-transform:translate3d(100%, 0, 0);
		-ms-transform:translate3d(100%, 0, 0);
		transform:translate3d(100%, 0, 0)
	}
}
@keyframes fadeOutRight{
	0%{
		opacity:1
	}
	100%{
		opacity:0;
		-webkit-transform:translate3d(100%, 0, 0);
		-ms-transform:translate3d(100%, 0, 0);
		transform:translate3d(100%, 0, 0)
	}
}
@-webkit-keyframes phonering-alo-circle-anim{
	0%{
		-webkit-transform:rotate(0) scale(.5) skew(1deg);
		transform:rotate(0) scale(.5) skew(1deg); opacity:.1
	}
	30%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.5
	}
	100%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg);
		opacity:.1
		}
}
@keyframes phonering-alo-circle-anim{
	0%{
		-webkit-transform:rotate(0) scale(.5) skew(1deg);
		transform:rotate(0) scale(.5) skew(1deg);
		opacity:.1
	}
	30%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.5
	}
	100%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg);
		opacity:.1
	}
}
@-webkit-keyframes phonering-alo-circle-fill-anim{
	0%{-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
	50%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg);
		opacity:.2
	}
	100%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
}
@keyframes phonering-alo-circle-fill-anim{
	0%{-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
	50%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg);
		opacity:.2
	}
	100%{
		-webkit-transform:rotate(0) scale(.7) skew(1deg);
		transform:rotate(0) scale(.7) skew(1deg);
		opacity:.2
	}
}
@-webkit-keyframes phonering-alo-circle-img-anim{
	0%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg)
	}
	10%{
		-webkit-transform:rotate(-25deg) scale(1) skew(1deg);
		transform:rotate(-25deg) scale(1) skew(1deg)
	}
	20%{
		-webkit-transform:rotate(25deg) scale(1) skew(1deg);
		transform:rotate(25deg) scale(1) skew(1deg)
	}
	30%{
		-webkit-transform:rotate(-25deg) scale(1) skew(1deg);
		transform:rotate(-25deg) scale(1) skew(1deg)
	}
	40%{
		-webkit-transform:rotate(25deg) scale(1) skew(1deg);
		transform:rotate(25deg) scale(1) skew(1deg)
	}
	50%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg)
	}
	100%{
		-webkit-transform:rotate(0) scale(1) skew(1deg);
		transform:rotate(0) scale(1) skew(1deg)
	}
}
@keyframes phonering-alo-circle-img-anim{
	0%{-webkit-transform:rotate(0) scale(1) skew(1deg); transform:rotate(0) scale(1) skew(1deg) }
	10%{-webkit-transform:rotate(-25deg) scale(1) skew(1deg); transform:rotate(-25deg) scale(1) skew(1deg) }
	20%{-webkit-transform:rotate(25deg) scale(1) skew(1deg); transform:rotate(25deg) scale(1) skew(1deg) }
	30%{-webkit-transform:rotate(-25deg) scale(1) skew(1deg); transform:rotate(-25deg) scale(1) skew(1deg) }
	40%{-webkit-transform:rotate(25deg) scale(1) skew(1deg); transform:rotate(25deg) scale(1) skew(1deg) }
	50%{-webkit-transform:rotate(0) scale(1) skew(1deg); transform:rotate(0) scale(1) skew(1deg) }
	100%{-webkit-transform:rotate(0) scale(1) skew(1deg); transform:rotate(0) scale(1) skew(1deg) }
}
.style-for-position-br{
	left: auto;
	right: 0
}
.style-for-position-cl{
	bottom: auto;
	top: 50%
}
.style-for-position-cr{
	left: auto;
	bottom: auto;
	top: 50%;
	right: 0
}
.style-for-position-tl{
	bottom: auto;
	top: 0
}
.style-for-position-tr{
	left: auto;
	bottom: auto;
	top: 0;
	right: 0
}
/*-----*/

	.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle{
		border-color: $hotline-color;
	}
	.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-circle-fill,.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle{
		background-color: $hotline-color;
	}
	/*.phonering-alo-phone.phonering-alo-green .phonering-alo-ph-img-circle a{color: $hotline-color}*/
	.phonering-alo-ph-img-circle {
		width: 45px;
		height: 45px;
		top: 42px;
		left: 43px
	}
	.phonering-alo-ph-img-circle a {
		width: 45px;
		line-height: 45px
	}
	.phonering-alo-ph-circle-fill {
		width: 90px;
		height: 90px;
		top: 20px;
		left: 20px
	}
	.hotline-alo-phone,.phonering-alo-ph-circle {
		width: 130px;
		height: 130px
	}
	.style-for-position-cr,.style-for-position-cl {
		margin-top: -67.5px;
	}
	/* for mobile */
	@media screen and (max-width:0px) {
		.style-for-position-bl {left: -45px;bottom: -45px}
		.style-for-position-br {right: -45px;bottom: -45px}
		.style-for-position-cl { left: -45px; }
		.style-for-position-cr { right: -45px; }
		.style-for-position-tl {top: -45px;left: -45px}
		.style-for-position-tr {top: -45px;right: -45px}
	}
	.hotline-alo-phone{display:block}/* Custom CSS */