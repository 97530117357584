#header {
    #navbarMenu {
        #navmenu {

            > li {
                @media screen and (min-width: 991px){

                    &:hover {
                        > ul {
                            display: block!important;
                            visibility: visible;
                            z-index: 2;
                        }
                    }

                    > ul {
                        > li {
                            &:hover {
                                > ul {
                                    display: block!important;
                                    visibility: visible;
                                    z-index: 2;
                                }
                            }
                        }

                    }
                }

                > ul {
                    &.show {
                        display: block!important;
                        visibility: visible;
                        z-index: 2;
                        width: 100%;
                        padding: 0 1.5rem;

                        a {
                            padding: 4px 0;
                            height: 34px;
                            line-height: 23px;
                            border-bottom: 1px solid #e07171;
                            background: transparent;
                            width: 100%;
                        }
                    }
                }

                .nav-caret {
                    position: absolute;
                    right: 10px;
                    top: 0;
                    padding: 5px 10px;
                    z-index: 5;
                }
            }
        }
    }
}

.nav-search {
    position: absolute;
    top: 7px;
    right: 22px;
    z-index: 1000;

    input {
        height: 32px;
    }
}

.btn-search-outline {
    position: absolute;
    top: 0;
    right: 0;
}