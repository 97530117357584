#widget_contact {
  position: fixed;
  bottom: 10%;
  right: 3rem;
  float: left;
  width: 50px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    bottom: 0;

  }

  .navbar-collapse {
      webkit-transition: .3s ease-out;
      transition: .3s ease-out;

    .show {

      webkit-transition: .3s ease-out;
      transition: .3s ease-out;
    }
  }
  .support {
    .icon-support {
      transform: rotate(45deg);
      webkit-transition: .3s ease-out;
      transition: .3s ease-out;
      cursor: pointer;
    }

    &.collapsed {

      .icon-support {
        transform: rotate(0deg);
        -webkit-transition: .3s ease-out;
        transition: .3s ease-out;
      }
    }
  }

  .icon-msg {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -214px -39px;
  }
  .icon-zalo {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -281px -39px;
  }
  .icon-wechat {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -272px -93px;
  }

  .fa-skype {
    border-radius: 50%;
    text-align: center;
    width: 43px;
    height: 43px;
    line-height: 43px;
    background-color: #02aff0;
    font-size: 1.7rem;

    &:hover {
      text-decoration: none;
    }
  }

  .icon-whatsapp {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -215px -93px;
  }
  .icon-support {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -156px -40px;
  }
  .icon-hotline {
    width: 43px;
    height: 43px;
    display: inline-block;
    background-position: -336px -39px;
  }
}